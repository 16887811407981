import React from 'react'
import { Helmet } from 'react-helmet'
import CustomerRegisterContainer from 'containers/panel/customer/register-container'
const CustomerRegisterPage = () => {
  return (
    <>
     <Helmet>
      <title>Kayıt | Greenpay</title>
    </Helmet>
    <CustomerRegisterContainer/>
    </>
  )
}

export default CustomerRegisterPage
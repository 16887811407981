import instance from "api/instance";


const createRetailOrder = async (data) => {
	const response = await instance.post("/reseller/orders/newretail",data);

	if (response.status === 201) {
		return response.data;
	}
	
	return Promise.reject(response.data);
};






const retailsService = {
	createRetailOrder
};

export default retailsService;
import React, { useEffect } from "react";
import { FaShoppingBag } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import OrderIndicator from "components/OrderIndicator";
import { orders } from "redux/admin/orders/ordersSlice";
const RecentOrders = () => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.adminOrders.orders);

  useEffect(() => {
    dispatch(orders());
  }, [dispatch]);
  return (
    <div className="w-full col-span-1 relative lg:h-[70vh]  h-[50vh] m-auto p-4  rounded-lg bg-gray-900 overflow-y-auto">
      <h1 className="text-white-100 font-medium text-lg">Son Siparişler</h1>
      <ul>
        {data?.map((item, index) => (
          <li
            key={index}
            className="bg-black rounded-lg my-3 p-2 flex items-center cursor-pointer"
          >
            <div className="bg-primary rounded-lg p-3">
              <FaShoppingBag className="text-white-100" />
            </div>
            <div className="pl-4">
              <p className="text-white-100 font-bold">

                  {
                      Intl.NumberFormat('tr-TR', {
                            style: 'currency',
                            currency: 'TRY'
                      }).format(item.payment.amount_try)
                  }
              </p>
              <p className="text-gray-300 text-xs">
                {item.payment.full_name}{" "}

              </p>
              <p>  {item.status === "completed" ? (
                  <OrderIndicator color="green">
                    Aktarım Yapıldı.
                  </OrderIndicator>
                ) : item.status === "pending_transfer" ? (
                  <OrderIndicator color="yellow">
                    Aktarım Bekliyor.
                  </OrderIndicator>
                ) : item.status === "failed" ? (
                  <OrderIndicator color="red">
                    Aktarım Reddedildi.
                  </OrderIndicator>
                ) : (
                  <OrderIndicator color="red">No Data</OrderIndicator>
                )}</p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default RecentOrders;

import Heading from 'components/Typography/Header'
import Paragraph from 'components/Typography/Paragraph'
import Solutions1 from 'images/icons/Solutions1.png'
import Solutions2 from 'images/icons/Solutions2.png'
import Solutions3 from 'images/icons/Solutions3.png'


const Solutions = () => {
    return (
        <section className='grid grid-cols-12 md:mt-60 mt-8'>
            <div className='md:col-start-3 md:col-end-10 col-start-1 col-end-13 text-center'>
                <Heading level={5} className="text-secondary">GreenPay ile güvenli ve hızlı E-Pin Alışverişi</Heading>
                <Heading level={2} className="text-gray-600">GreenPay Her Zaman Seninle!</Heading>
                <Paragraph className="text-black">GreenPay, e-pinlerinizi hızlı ve güvenli bir şekilde satın almanızı sağlayan kullanıcı dostu bir işlem merkezidir.</Paragraph>
            </div>
            <div className='col-start-1 col-end-13 grid grid-cols-12 gap-[30px] mt-8'>
                <div className='partner-shadow px-[30px] py-[50px] md:col-start-1 md:col-end-5 col-start-1 col-end-13 flex flex-col items-center text-center'>
                    <div className='w-[140px] h-[140px] bg-primary flex items-center justify-center rounded-full'>
                        <img src={Solutions1} width={80} height={80} />
                    </div>
                    <div className='mt-8 space-y-[15px]'>
                        <Heading level={5} className="text-gray-600">Kolayca Üye Ol</Heading>
                        <Paragraph className="text-black">GreenPay ile üye olmak çok kolay! Hemen GreenPay'e üye ol ve ayrıcalıklardan sen de faydalan.</Paragraph>
                    </div>
                </div>
                <div className='partner-shadow px-[30px] py-[50px] md:col-start-5 md:col-end-9 col-start-1 col-end-13 flex flex-col items-center text-center'>
                    <div className='w-[140px] h-[140px] bg-primary  flex items-center justify-center rounded-full'>
                        <img src={Solutions2} width={80} height={80} />
                    </div>
                    <div className='mt-8 space-y-[15px]'>
                        <Heading level={5} className="text-gray-600">Güvenle Para Yatır</Heading>
                        <Paragraph className="text-black">GreenPay ile hızlı ve güvenli para yatırma işlemleri yap. Hemen para yatır ve oyun dünyasına adım at.</Paragraph>
                    </div>
                </div>
                <div className='partner-shadow px-[30px] py-[50px] md:col-start-9 md:col-end-13 col-start-1 col-end-13 flex flex-col items-center text-center'>
                    <div className='w-[140px] h-[140px] bg-primary flex items-center justify-center rounded-full'>
                        <img src={Solutions3} width={80} height={80} />
                    </div>
                    <div className='mt-8 space-y-[15px]'>
                        <Heading level={5} className="text-gray-600">Pin Satın Al</Heading>
                        <Paragraph className="text-black">GreenPay ile oyun pinlerini kolayca satın al. Pin satın alarak favori oyunlarında vakit geçirmeye başla.</Paragraph>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default Solutions
import React, { useEffect,useState } from 'react'
import Orders from 'components/Panel/Admin/Orders'
import { useDispatch, useSelector } from 'react-redux'
import { orders } from 'redux/admin/orders/ordersSlice';
import Pagination from 'components/Pagination';
import { useNavigate } from 'react-router';
import { useDebounce } from 'use-debounce';

const OrdersContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [onlyAdmin, setOnlyAdmin] = useState(true);
  const [inputSearch, setInputSearch] = useState('');
  const [debouncedSearch] = useDebounce(inputSearch, 500);
  const { data } = useSelector(state => state.adminOrders.orders);
  const [intervalId, setIntervalId] = useState(null);
  const { total, pages, page, per_page } = useSelector(state => state.adminOrders.orders);

  useEffect(() => {
    const fetchOrders = () => {
      dispatch(orders({ page: page, per_page: 20, only_admin: onlyAdmin, order_by: "created_at", order_direction: "desc",search:debouncedSearch }))
    };
    fetchOrders();
    const id = setInterval(fetchOrders, 10000);
    return () => clearInterval(id);
  }, [dispatch, onlyAdmin,page,debouncedSearch]);

  const handlePageChange = (newPage) => {
    clearInterval(intervalId);
    dispatch(orders({ page: newPage, per_page: 20, only_admin: onlyAdmin, order_by: "created_at", order_direction: "desc",search:debouncedSearch }))
  };




  return (
    <div className='bg-black min-h-screen'>
      <div className='p-4 flex gap-4 items-center'>
        <h2 className='text-xl font-semibold text-white-100'>Siparişler</h2>
        <span onClick={() => navigate(0)} className='bg-secondary text-white-100 px-2 py-1 rounded-md cursor-pointer'>Yenile</span>
        <div className='flex items-center gap-2'>
          <span onClick={() => setOnlyAdmin(false)} className='bg-white-200 text-black-100 px-2 py-1 rounded-md cursor-pointer'>Tüm Siparişler</span>
        <span onClick={() => setOnlyAdmin(true)} className='bg-primary text-white-100 px-2 py-1 rounded-md cursor-pointer'>Bana Özel Siparişler</span>
        </div>
      </div>
      <Orders data={data} inputSearch={inputSearch} setInputSearch={setInputSearch}  />
      <Pagination currentPage={page} totalPages={pages} onPageChange={handlePageChange} />
    </div>
  )
}

export default OrdersContainer

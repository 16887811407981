import React, { useEffect, useState } from "react";
import { RxSketchLogo } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import {
  getTotalSales,
  getResellerPayouts,
  getResellerDiamondTransfers,
  resetTotalSales,
} from "redux/admin/stats/statsSlice";

const TopPanel = () => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState("");
  const [startDateResellerPayouts, setStartDateResellerPayouts] = useState(
    () => {
      const date = new Date();
      date.setUTCDate(1);
      return date.toISOString().slice(0, 10);
    }
  );

  const [endDateResellerPayouts, setEndDateResellerPayouts] = useState(() => {
    const date = new Date();
    date.setUTCMonth(date.getUTCMonth() + 1);
    date.setUTCDate(1);
    return date.toISOString().slice(0, 10);
  });
  const [
    startDateResellerDiamondTransfers,
    setStartDateResellerDiamondTransfers,
  ] = useState(() => {
    const date = new Date();
    date.setUTCDate(1);
    return date.toISOString().slice(0, 10);
  });

  const [endDateResellerDiamondTransfers, setEndDateResellerDiamondTransfers] =
    useState(() => {
      const date = new Date();
      date.setUTCMonth(date.getUTCMonth() + 1);
      date.setUTCDate(1);
      return date.toISOString().slice(0, 10);
    });

  const [startDateTotalSales, setStartDateTotalSales] = useState(() => {
    const date = new Date();
    date.setUTCDate(1);
    return date.toISOString().slice(0, 10);
  });

  const [endDateTotalSales, setEndDateTotalSales] = useState(() => {
    const date = new Date();
    date.setUTCMonth(date.getUTCMonth() + 1);
    date.setUTCDate(1);
    return date.toISOString().slice(0, 10);
  });

  const { totalSales, resellerPayouts, resellerDiamondTransfers } = useSelector(
    (state) => state.adminStats
  );

  useEffect(() => {
    const utcMinusThreeHours = -3 * 60 * 60 * 1000;
    const startDateTime = (date) => {
      return new Date(`${date}T00:00:00.000Z`).getTime() + utcMinusThreeHours;
    };
    const endDateTime = (date) => {
      return new Date(`${date}T23:59:00.000Z`).getTime() + utcMinusThreeHours;
    };

    dispatch(
      getResellerPayouts({
        start: new Date(startDateTime(startDateResellerPayouts)).toISOString(),
        end: new Date(endDateTime(endDateResellerPayouts)).toISOString(),
      })
    );
  }, [startDateResellerPayouts, endDateResellerPayouts]);

  useEffect(() => {
    const utcMinusThreeHours = -3 * 60 * 60 * 1000;
    const startDateTime = (date) => {
      return new Date(`${date}T00:00:00.000Z`).getTime() + utcMinusThreeHours;
    };
    const endDateTime = (date) => {
      return new Date(`${date}T23:59:00.000Z`).getTime() + utcMinusThreeHours;
    };

    dispatch(
      getResellerDiamondTransfers({
        start: new Date(
          startDateTime(startDateResellerDiamondTransfers)
        ).toISOString(),
        end: new Date(
          endDateTime(endDateResellerDiamondTransfers)
        ).toISOString(),
      })
    );
  }, [startDateResellerDiamondTransfers, endDateResellerDiamondTransfers]);

  useEffect(() => {
    const utcMinusThreeHours = -3 * 60 * 60 * 1000;
    const startDateTime = (date) => {
      return new Date(`${date}T00:00:00.000Z`).getTime() + utcMinusThreeHours;
    };
    const endDateTime = (date) => {
      return new Date(`${date}T23:59:00.000Z`).getTime() + utcMinusThreeHours;
    };

    dispatch(
      getTotalSales({
        start: new Date(startDateTime(startDateTotalSales)).toISOString(),
        end: new Date(endDateTime(endDateTotalSales)).toISOString(),
      })
    );
    return () => {
      dispatch(resetTotalSales());
    };
  }, [startDateTotalSales, endDateTotalSales]);

  return (
    <div>
      {selected === "resellerPayouts" ? (
        <div className="flex items-center gap-4 px-4">
          <p className="px-4 text-white-100 font-medium">
            Toplam Komisyon Kazanç
          </p>
          <input
            type="date"
            value={startDateResellerPayouts}
            onChange={(e) => setStartDateResellerPayouts(e.target.value)}
            className="rounded-lg px-3 py-2 mx-2"
          />
          <span className="text-white-100 text-xl">-</span>
          <input
            type="date"
            value={endDateResellerPayouts}
            onChange={(e) => setEndDateResellerPayouts(e.target.value)}
            className="rounded-lg px-3 py-2 mx-2"
          />
        </div>
      ) : selected === "resellerDiamondTransfers" ? (
        <div className="flex gap-4 items-center px-4">
          <p className="px-4 text-white-100 font-medium">
            Toplam Elmas Komisyon Kazanç
          </p>
          <input
            type="date"
            value={startDateResellerDiamondTransfers}
            onChange={(e) =>
              setStartDateResellerDiamondTransfers(e.target.value)
            }
            className="rounded-lg px-3 py-2 mx-2"
          />
          <span className="text-white-100 text-xl">-</span>
          <input
            type="date"
            value={endDateResellerDiamondTransfers}
            onChange={(e) => setEndDateResellerDiamondTransfers(e.target.value)}
            className="rounded-lg px-3 py-2 mx-2"
          />
        </div>
      ) : selected === "totalSales" ? (
        <div className="flex gap-4 px-4 items-center">
          <p className="px-4 text-white-100 font-medium">
            Toplam Elmas Satışı ve Toplanan Para
          </p>
          <input
            type="date"
            value={startDateTotalSales}
            onChange={(e) => setStartDateTotalSales(e.target.value)}
            className="rounded-lg px-3 py-2 mx-2"
          />
          <span className="text-white-100 text-xl">-</span>
          <input
            type="date"
            value={endDateTotalSales}
            onChange={(e) => setEndDateTotalSales(e.target.value)}
            className="rounded-lg px-3 py-2 mx-2"
          />
        </div>
      ) : (
        <p className="px-4 text-white-100 font-medium">
          Tarih Aralığı Seçmek İçin Kartlara Tıklayın
        </p>
      )}
      <div className="grid lg:grid-cols-4  gap-4 p-4">
        <div
          onClick={() => setSelected("resellerPayouts")}
          className="lg:col-span-1 col-span-1 bg-gray-900 flex justify-between w-full p-4 rounded-lg cursor-pointer"
        >
          <div className="flex items-center gap-4 rounded-lg p-6">
            <span className="rounded-full bg-primary p-3 text-white-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-8 w-8"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"
                />
              </svg>
            </span>

            <div>
              <p className="text-2xl font-medium text-white-100">
                {
                  Intl.NumberFormat("tr-TR",{
                    currency: "TRY",
                    style: "currency",
                  }).format(resellerPayouts?.fee_amount || 0)

                }
              </p>

              <p className="text-sm text-white-100">Toplam Komisyon Kazanç</p>
            </div>
          </div>
        </div>
        <div
          onClick={() => setSelected("resellerDiamondTransfers")}
          className="lg:col-span-1 col-span-1 bg-gray-900 flex justify-between w-full p-4 rounded-lg cursor-pointer"
        >
          <div className="flex items-center gap-4 rounded-lg p-6">
            <span className="rounded-full bg-primary p-3 text-white-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-8 w-8"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"
                />
              </svg>
            </span>

            <div>
              <p className="text-2xl font-medium text-white-100">
                {
                  Intl.NumberFormat("tr-TR",{
                    currency: "TRY",
                    style: "currency",
                  }).format(resellerDiamondTransfers?.total_fee || 0)

                }

              </p>

              <p className="text-sm text-white-100">
                Toplam Elmas Komisyon Kazanç
              </p>
            </div>
          </div>
        </div>
        <div
          onClick={() => setSelected("totalSales")}
          className="lg:col-span-1 col-span-1 bg-gray-900 flex justify-between w-full p-4 rounded-lg cursor-pointer"
        >
          <div className="flex items-center gap-4 rounded-lg p-6">
            <span className="rounded-full bg-primary p-3 text-white-100">
              <RxSketchLogo size={30} />
            </span>

            <div>
              <p className="text-2xl font-medium text-white-100">
                {
                  Intl.NumberFormat("tr-TR").format(totalSales?.total_diamond_sales || 0)

                }

              </p>

              <p className="text-sm text-white-100">Toplam Elmas Satışı</p>
            </div>
          </div>
        </div>
        <div
          onClick={() => setSelected("totalSales")}
          className="lg:col-span-1 col-span-1 bg-gray-900 flex justify-between w-full p-4 rounded-lg cursor-pointer"
        >
          <div className="flex items-center gap-4 rounded-lg p-6">
            <span className="rounded-full bg-primary p-3 text-white-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-8 w-8"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"
                />
              </svg>
            </span>

            <div>
              <p className="text-2xl font-medium text-white-100">

                {
                  Intl.NumberFormat("tr-TR",{
                    currency: "TRY",
                    style: "currency",
                  }).format(totalSales?.total_try_sales || 0)

                }
              </p>

              <p className="text-sm text-white-100">Toplanan Para</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopPanel;

import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import Sidebar from "components/Panel/Admin/Sidebar";
import { useSelector, useDispatch } from "react-redux";
import { profile } from "redux/admin/profile/profileSlice"
import { settings } from "redux/admin/settings/settingsSlice";
import { Helmet } from "react-helmet";


function DashboardLayout() {
  const dispatch = useDispatch();
  const { user, role } = useSelector((state) => state.adminAuth);
  const location = useLocation();
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(false)

  const handleToggleSidebar = () => {
    setIsSidebarExpanded(!isSidebarExpanded);
  };

  useEffect(() => {
    setIsSidebarExpanded(false);
  }, [location])

  useEffect(() => {
    dispatch(profile());
    dispatch(settings())
  }, [dispatch])

  if (!user || role !== "admin") {
    return <Navigate to="/login/admin" />;
  }
  return (
    <div className="min-h-screen flex bg-black">
      <Helmet>
        <title>GreenPay | Admin Panel</title>
        <meta name="description" content="GreenPay | Admin Panel" />
      </Helmet>
      <Sidebar toggle={isSidebarExpanded} />
      <main className="md:ml-20 w-full">
        <div className="text-white-100 ml-4 my-2 inline-block md:hidden bg-primary px-4 py-1 font-semibold rounded-md " onClick={handleToggleSidebar}>Menü</div>
        <Outlet />
      </main>
      <ToastContainer />
    </div>
  );
}

export default DashboardLayout;

import Heading from "components/Typography/Header";

const KVKKPolitikasi = () => {
  return (
    <div className="green-container py-4">
      <Heading className="text-white-100 text-center" level={3}>
        KVKK Politikası
      </Heading>

      <div className="text-white-100">
        <div className="space-y-4">
          <p></p>
          <p>
            <span className="font-semibold">
              KİŞİSEL VERİLERİN KORUNMASI VE GİZLİLİK POLİTİKASI
            </span>
          </p>
          <p></p>
          İşbu Kişisel Verilerin Korunması ve İmha Politikası, GREENPAY ELEKTRONİK
          TİCARET A.Ş. (“GREENPAY”) olarak veri sorumlusu sıfatıyla elimizde
          bulundurduğumuz kişisel verilerin 6698 sayılı Kişisel Verilerin
          Korunması Kanunu ve sair mevzuatı uyarınca kişisel verilerin
          silinmesi, yok edilmesi veya anonim hale getirilmesine ilişkin Greenpay
          tarafından uygulanacak usul ve esasların belirlenmesi amacıyla
          hazırlanmıştır. Bu kapsamda, üye işyerlerimiz ve kullanıcılarımız
          başta olmak üzere Greenpay nezdinde kişisel verisi bulunan tüm gerçek
          kişilerin kişisel verileri Kişisel Verilerin İşlenmesi ve Korunması
          Politikası ve işbu Kişisel Veri Saklama ve İmha Politikası
          çerçevesinde kanunlara uygun olarak yönetilmektedir.
          <p></p>
          <p>
            <span className="font-semibold">1. VERİ GİZLİLİĞİ TAAHHÜDÜ</span>
          </p>
          <p></p>
          <p>
            <span>
              1.1 İşbu Kişisel Verilerin Korunması Politikası (“Politika”), Greenpay
              Elektronik Ticaret A.Ş.’nin (“Şirket”) 6698 Sayılı Kişisel
              Verilerin Korunması Kanunu başta olmak üzere ilgili mevzuat
              hükümleri uyarınca Kişisel Verileri korumaya yönelik
              yükümlülüklerini yerine getirirken ve Kişisel Verileri işlerken
              Şirket içerisinde ve/veya Şirket tarafından uyulması gereken
              esasları belirlemektedir.
            </span>
          </p>
          <p></p>
          <p>
            <span>
              1.2 Şirket, kendi bünyesinde bulunan Kişisel Veriler bakımından
              işbu Politikaya ve Politikaya bağlı olarak uygulanacak
              prosedürlere uygun davranmayı taahhüt eder.
            </span>
          </p>
          <p></p>
          <p>
            <span className="font-semibold">2. POLİTİKANIN AMACI</span>
          </p>
          İşbu Politikanın temel amacı, Şirket tarafından Kişisel Verilerin
          işlenmesine ve korunmasına yönelik yöntem ve süreçlere ilişkin
          esasları belirlemektir.
          <p></p>
          <p>
            <span className="font-semibold">3. POLİTİKANIN KAPSAMI</span>
          </p>
          <p></p>
          <p>
            <span>
              3.1 İşbu Politika, Şirketin işlemekte olduğu Kişisel Verilere
              yönelik tüm faaliyetleri kapsar ve söz konusu faaliyetlere
              uygulanır.
            </span>
          </p>
          <p></p>
          <p>
            <span>
              3.2 İşbu Politika, Kişisel Veri niteliği taşımayan verilere
              uygulanmaz.
            </span>
          </p>
          <p></p>
          <p>
            <span>
              3.3 İşbu Politika, KVKK Düzenlemelerinin gerektirmesi halinde
              yahut Şirketin Veri Sorumlusu İrtibat Kişisi yahut Komite’nin
              gerekli gördüğü hallerde zaman zaman Yönetim Kurulu onayı ile
              değiştirilebilir. KVK Düzenlemeleri ve işbu Politika arasında bir
              uyumsuzluk olması halinde KVK Düzenlemeleri esas alınır.
            </span>
          </p>
          <p></p>
          <p>
            <span className="font-semibold">
              4. KİŞİSEL VERİ İŞLEMENİN İLKELERİ
            </span>
          </p>
          <p></p>
          <p>
            <span>
              4.1. Şirket, Kişisel Verileri, hukuka ve dürüstlük kurallarına
              uygun ve ölçülülük esasına dayalı olarak işler.
            </span>
          </p>
          <p></p>
          <p>
            <span>
              4.2. Şirket, Kişisel Verilerin eksiksiz, doğru ve güncel olması
              için her türlü gerekli önlemleri alır ve Veri Öznesinin KVK
              Düzenlemeleri kapsamında Kişisel Verilere yönelik değişiklik talep
              etmesi durumunda ilgili Kişisel Verileri günceller.
            </span>
          </p>
          <p></p>
          <p>
            <span>
              4.3. Kişisel Verilerin İşlenmesinden önce Şirket tarafından
              Kişisel Verilerin hangi amaçla işleneceği belirlenir. Bu kapsamda,
              Veri Öznesi KVK Düzenlemeleri kapsamında aydınlatılır ve gereken
              hallerde Açık Rızaları alınır.
            </span>
          </p>
          <p></p>
          <p>
            <span>
              4.4. Şirket, Kişisel Verileri yalnızca KVK Düzenlemeleri
              kapsamında istisnai hallerde (KVKK Madde 5.2 ve Madde 6.3) veya
              Veri Öznesinden alınan Açık Rıza kapsamındaki amaç doğrultusunda
              (KVKK Madde 5.1 ve Madde 6.2) ve ölçülülük esasına uygun olarak
              işler. Veri Sorumlusu, Kişisel Verileri belirlenen amaçların
              gerçekleştirilebilmesine elverişli bir biçimde işler ve amacın
              gerçekleştirilmesiyle ilgili olmayan veya ihtiyaç duyulmayan
              Kişisel Verileri işlemekten kaçınır.
            </span>
          </p>
          <p></p>
          <p>
            <span>
              4.5. Kişisel Verilerin İlgili Mevzuatta Öngörülen veya
              İşlendikleri Amaç için Gerekli Olan Süre Kadar Muhafaza Edilmesi
            </span>
          </p>
          <p></p>
          <p>
            <span>
              4.5.1. Şirket, Kişisel Verileri amaca uygun olarak gerektiği kadar
              muhafaza eder. Şirketin, KVK Düzenlemelerinde öngörülen veya
              Kişisel Veri İşleme amacının gerektirdiği süreden daha uzun bir
              süreyle Kişisel Verileri muhafaza etmek istemesi halinde, Şirket
              KVK Düzenlemelerinde belirtilen yükümlülüklere uygun davranır.
            </span>
          </p>
          <p></p>
          <p>
            <span>
              4.5.2. Kişisel Veri İşleme amacının gerektirdiği süre sona
              erdikten sonra Kişisel Veriler Silinir veya Anonim Hale Getirilir.
              İşbu halde, Şirketin Kişisel Verileri aktardığı üçüncü kişilerin
              de Kişisel Verileri Silmesi, Yok Etmesi yahut Anonim Hale
              Getirmesi sağlanır.
            </span>
          </p>
          <p></p>
          <p>
            <span>
              4.5.3. Silme, Yok Etme ve Anonim Hale Getirme süreçlerinin
              işletilmesinden Veri Sorumlusu İrtibat Kişisi ve Komite
              sorumludur. Bu kapsamda gerekli prosedür Veri Sorumlusu İrtibat
              Kişisi ve Komite tarafından oluşturulur.
            </span>
          </p>
          <p></p>
          <p>
            <span className="font-semibold">5. KİŞİSEL VERİLERİN İŞLENMESİ</span>
          </p>
          Kişisel Veriler Şirket tarafından ancak aşağıda belirtilen usul ve
          esaslar kapsamında işlenebilir.
          <p></p>
          <p></p>
          <p>
            <span>5.1 Açık Rıza</span>
          </p>
          <p></p>
          <p>
            <span>
              5.1.1. Kişisel Veriler, Veri Öznelerine Aydınlatma Yükümlülüğünün
              yerine getirilmesi çerçevesinde yapılacak bilgilendirme sonrası ve
              Veri Öznelerinin Açık Rıza vermesi halinde işlenir.
            </span>
          </p>
          <p></p>
          <p>
            <span>
              5.1.2. Aydınlatma Yükümlülüğü çerçevesinde Açık Rıza alınmadan
              önce Veri Öznelerine hakları bildirilir.
            </span>
          </p>
          <p></p>
          <p>
            <span>
              5.1.3. Veri Öznesinin Açık Rızası, KVK Düzenlemelerine uygun
              yöntemlerle alınır. Açık Rızalar ispatlanabilir şekilde Şirket
              tarafından KVK Düzenlemeleri kapsamında gereken süre ile muhafaza
              edilir.
            </span>
          </p>
          <p></p>
          <p>
            <span>
              5.1.4. Veri Sorumlusu İrtibat Kişisi ve Komite, tüm Kişisel Veri
              İşleme süreçleri bakımından Aydınlatma Yükümlülüğünün yerine
              getirilmesini ve gerektiğinde Açık Rızanın alınmasını ve alınan
              Açık Rıza’nın muhafazasını sağlamakla yükümlüdür. Kişisel Veri
              İşleyen tüm departman çalışanları Veri Sorumlusu İrtibat Kişisi ve
              Komitenin talimatlarına, işbu Politika’ya ve bu Politika’nın eki
              olan KVK Prosedürlerine uymakla yükümlüdür.
            </span>
          </p>
          <p></p>
          <p>
            <span>5.2 Kişisel Verilerin Açık Rıza Alınmaksızın İşlenmesi</span>
          </p>
          <p></p>
          <p>
            <span>
              5.2.1 KVK Düzenlemeleri kapsamında Açık Rıza alınmaksızın Kişisel
              Verilerin İşlenmesinin öngörüldüğü durumlarda (KVKK Madde 5.2),
              Şirket Veri Öznesinin Açık Rızasını almaksızın Kişisel Verileri
              işleyebilir. Kişisel Verilerin bu şekilde işlenmesi durumunda
              Şirket KVK Düzenlemelerinin çizdiği sınırlar çerçevesinde Kişisel
              Verileri İşler. Bu kapsamda: Kanunlarda açıkça öngörülmesi halinde
              Kişisel Veriler Şirket tarafından Açık Rıza olmaksızın
              işlenebilir. Fiili imkânsızlık nedeniyle rızasını açıklayamayacak
              durumda bulunan veya rızasına hukuki geçerlilik tanınmayan Veri
              Öznesinin kendisinin ya da Veri Öznesi dışındaki bir başkasının
              hayatı veya beden bütünlüğünün korunması için zorunlu olması
              halinde Kişisel Veriler Şirket tarafından Açık Rıza olmaksızın
              işlenebilir. Bir sözleşmenin kurulması veya ifasıyla doğrudan
              doğruya ilgili olması kaydıyla, sözleşmenin taraflarına ait
              Kişisel Verilerin işlenmesinin gerekli olması halinde Kişisel
              Veriler Veri Öznelerinin Açık Rızaları olmadan Şirket tarafından
              işlenebilir. Kişisel Verilerin İşlenmesi Şirketin hukuki
              yükümlülüğünü yerine getirmesi için zorunluysa, Kişisel Veriler
              Veri Öznelerinin Açık Rızaları olmadan Şirket tarafından
              işlenebilir. Veri Öznesi tarafından alenileştirilmiş olan Kişisel
              Veriler Açık Rıza alınmaksızın Şirket tarafından işlenebilir.
              Kişisel Verilerin İşlenmesi bir hakkın tesisi, kullanılması veya
              korunması için zorunlu ise Kişisel Veriler Açık Rıza alınmaksızın
              Şirket tarafından işlenebilir. Veri Öznesinin temel hak ve
              özgürlüklerine zarar vermemek kaydıyla, Şirketin meşru menfaatleri
              için veri işlenmesinin zorunlu olması halinde Kişisel Veriler
              Şirket tarafından Açık Rıza olmaksızın işlenebilir.
            </span>
          </p>
          <p></p>
          <p>
            <span>
              5.3 Kişisel Verilerin Saklandığı Ortamlar Greenpay nezdinde saklanan
              kişisel veriler, ilgili verinin niteliğine ve hukuki
              yükümlülüklerimize uygun bir kayıt ortamında tutulur. Kişisel
              verilerin saklanması için kullanılan kayıt ortamları genel
              itibariyle aşağıda sayılanlardır. Ancak, bir kısım veriler sahip
              oldukları özel nitelikler ya da hukuki yükümlülüklerimiz nedeniyle
              burada gösterilen ortamlardan farklı bir ortamda tutulabilir. Greenpay
              her halde veri sorumlusu sıfatıyla hareket etmekte ve kişisel
              verileri Kanun’a, Kişisel Verilerin İşlenmesi ve Korunması
              Politikası’na ve işbu Kişisel Veri Saklama ve İmha Politikası’na
              uygun olarak işlemek ve korumaktadır.
            </span>
          </p>
          <p></p>
          <p>
            <span className="font-semibold">
              6. ÖZEL NİTELİKLİ KİŞİSEL VERİLERİN İŞLENMESİ
            </span>
          </p>
          <p></p>
          <p></p>
          <p>
            <span>
              6.1. Özel Nitelikli Kişisel Veriler yalnızca Veri Öznesinin Açık
              Rızasının bulunması yahut, cinsel hayat ve kişisel sağlık verileri
              dışındaki Özel Nitelikli Kişisel Veriler bakımından kanunlarda
              açıkça işlemenin zorunlu tutulması halinde işlenebilir.
            </span>
          </p>
          <p></p>
          <p>
            <span>
              6.2. Sağlık ve cinsel hayata ilişkin Kişisel Veriler, ancak kamu
              sağlığının korunması, koruyucu hekimlik, tıbbî teşhis, tedavi ve
              bakım hizmetlerinin yürütülmesi, sağlık hizmetleri ile
              finansmanının planlanması ve yönetimi amacıyla, sır saklama
              yükümlülüğü altında bulunan kişiler (örn: Şirket hekimi) veya
              yetkili kurum ve kuruluşlar tarafından Açık Rıza almaksızın
              işlenebilir.
            </span>
          </p>
          <p></p>
          <p>
            <span>
              6.3. Özel Nitelikli Kişisel Veriler İşlenirken, Kurul tarafından
              belirlenen önlemler alınır.
            </span>
          </p>
          <p></p>
          <p>
            <span>
              6.4. Şirket, Özel Nitelikli Kişisel Verilerin işlenmesi
              süreçlerinde yer alan çalışanlara yönelik, KVKK Düzenlemeleri ile
              Özel Nitelikli Kişisel Verilerin güvenliği konularında düzenli
              olarak eğitimler verecek, gizlilik sözleşmeleri yapacaktır. Bu
              kapsamda ; Özel Nitelikli Kişisel Verilere erişim yetkisine sahip
              kullanıcıların yetki kapsamlarını ve sürelerini net olarak
              tanımlayacaktır. Periyodik olarak yetki kontrollerini
              gerçekleştirecektir. Görev değişikliği olan ya da işten ayrılan
              çalışanların bu alandaki yetkilerini derhal kaldırır ve ilgili
              çalışana tahsis edilen envanteri derhal geri alacaktır.
            </span>
          </p>
          <p></p>
          <p>
            <span>
              6.5 Özel Nitelikli elektronik ortamlara aktarılması durumunda,
              Özel Nitelikli Verilerin işlendiği, muhafaza edildiği ve/veya
              erişildiği elektronik ortamlar ile ilgili olarak Şirket; Özel
              Nitelikli Kişisel Verileri kriptografik yöntemler kullanarak
              muhafaza edecektir. Kriptografik anahtarları güvenli ve farklı
              ortamlarda tutacaktır. Özel Nitelikli Kişisel Veriler üzerinde
              gerçekleştirilen tüm hareketlerin işlem kayıtlarını güvenli olarak
              loglayacaktır. Özel Nitelikli Kişisel Verilerin bulunduğu
              ortamlara ait güvenlik güncellemelerini sürekli takip edecek,
              gerekli güvenlik testlerini düzenli olarak yapacak/yaptıracak,
              test sonuçlarını kayıt altına alacaktır. Özel Nitelikli Kişisel
              Verilere bir yazılım aracılığıyla erişiliyorsa bu yazılıma ait
              kullanıcı yetkilendirmelerini yapacak, bu yazılımların güvenlik
              testlerini düzenli olarak yapacak/yaptıracak, test sonuçlarını
              kayıt altına alacaktır. Özel Nitelikli Kişisel Verilere uzaktan
              erişim olması halinde en az iki kademeli kimlik doğrulama sistemi
              sağlayacaktır.
            </span>
          </p>
          <p></p>
          <p>
            <span>
              6.6 Özel Nitelikli Kişisel Verilerin fiziksel ortamda işlenmesi
              durumunda, Verilerin işlendiği, muhafaza edildiği ve/veya
              erişildiği fiziksel ortamlar ile ilgili olarak Şirket; Özel
              Nitelikli Kişisel Verilerin bulunduğu ortamın niteliğine göre
              yeterli güvenlik önlemlerinin (elektrik kaçağı, yangın, su
              baskını, hırsızlık vb. durumlara karşı) alındığından emin
              olacaktır. Bu ortamların fiziksel güvenliğini sağlayarak yetkisiz
              giriş çıkışları engelleyecektir.
            </span>
          </p>
          <p></p>
          <p>
            <span>
              6.7. Özel Kişisel Verilerin aktarılması halinde, Veri Sorumlusu;
              Özel Nitelikli Kişisel Verilerin e-posta yoluyla aktarılmasının
              gerekmesi halinde şifreli kurumsal e-posta adresi veya Kayıtlı
              Elektronik Posta (“KEP”) hesabı kullanacaktır. Özel Nitelikli
              Kişisel Verilerin taşınabilir bellek, CD, DVD gibi ortamlar
              yoluyla aktarımın gerekli olması halinde kriptografik yöntemlerle
              şifreleme yapar ve kriptografik anahtarı farklı ortamda
              tutacaktır. Özel Nitelikli Kişisel Verilerin farklı fiziksel
              ortamlardaki sunucular arasında aktarılması gerekiyorsa, sunucular
              arasında VPN kurarak veya SFTP yöntemiyle aktarımı
              gerçekleştirecektir. Özel Nitelikli Kişisel Verilerin kağıt ortamı
              yoluyla aktarımının gerekli olması halinde, evrakın çalınması,
              kaybolması ya da yetkisiz kişiler tarafından görülmesi gibi
              risklere karşı gerekli önlemleri alır ve evrakı “gizlilik dereceli
              belgeler” formatında gönderecektir.
            </span>
          </p>
          <p></p>
          <p>
            <span>
              6.8. Yukarıdaki düzenlemelere ek olarak, Komite ve Veri Sorumlusu
              İrtibat Kişisi Özel Nitelikli Veriler dahil Kişisel Verilerin
              güvenliğinin sağlanmasına ilişkin Kurul tarafından yayımlanan
              başta Kişisel Veri Güvenliği Rehberi olmak üzere KVK
              Düzenlemelerine uygun hareket edecektir.
            </span>
          </p>
          <p></p>
          <p>
            <span>
              6.9. Özel Nitelikli Kişisel Verilerin İşlenmesini gerektiren her
              durumda, ilgili çalışan tarafından Veri Sorumlusu Temsilcisi
              bilgilendirilir.
            </span>
          </p>
          <p></p>
          <p>
            <span>
              6.10. Bir verinin Özel Nitelikli Kişisel Veri olup olmadığı
              anlaşılabilir değil ise ilgili departman tarafından Veri Sorumlusu
              İrtibat Kişisinden görüş alınır.
            </span>
          </p>
          <p></p>
          <p>
            <span className="font-semibold">
              7. KİŞİSEL VERİLERİN SAKLANMA SÜRESİ
            </span>
          </p>
          Kişisel Veriler, Şirket bünyesinde ilgili yasal saklama süreleri
          müddetince bulundurulmakta olup, bu verilerle ilişkili faaliyetlerin
          ve işbu Politika’da da belirtilen amaçların gerçekleştirilmesi için
          gerekli süre boyunca saklanmaktadır. Kullanım amacı sonlanan ve yasal
          saklama süresi sona eren Kişisel Veriler ise, KVKK’nın 7’nci maddesi
          uyarınca Şirket tarafından silinmekte, yok edilmekte veya anonim hale
          getirilmektedir.
          <p></p>
          <p>
            <span className="font-semibold">
              8. KİŞİSEL VERİLERİN SİLİNMESİ, YOK EDİLMESİ VE ANONİM HALE
              GETİRİLMESİ
            </span>
          </p>
          <p></p>
          <p>
            <span>
              8.1. Kişisel Verinin İşlenmesine yönelik meşru amaç ortadan
              kalktığında, ilgili Kişisel Veriler Silinir, Yok Edilir yahut
              Anonim Hale Getirilir. Kişisel Verilerin Silinmesi, Yok Edilmesi
              yahut Anonim Hale Getirilmesi gereken durumlar Veri Sorumlusu
              İrtibat Kişisi ve Komite tarafından takip edilir.
            </span>
          </p>
          <p></p>
          <p>
            <span>
              8.2. Silme, Yok Etme ve Anonim Hale Getirme süreçlerinin
              işletilmesinden Veri Sorumlusu İrtibat Kişisi ve Komite
              sorumludur. Bu kapsamda gerekli prosedür Veri Sorumlusu Temsilcisi
              ve Komite tarafından oluşturulur.
            </span>
          </p>
          <p></p>
          <p>
            <span>
              8.3. Şirket, Kişisel Verileri gelecekte kullanma ihtimalini göz
              önünde bulundurarak saklamaz.
            </span>
          </p>
          <p></p>
          <p>
            <span>
              8.4. Şirket’in Kişisel Veriler üzerinde uygulayacağı tüm Silme,
              Yok Etme ve Anonim Hale Getirme faaliyetleri Kişisel Veri Saklama,
              İmha ve Anonimleştirme Politikası’nda belirtilen esaslara uygun
              olarak gerçekleştirilecektir.
            </span>
          </p>
          <p></p>
          <p>
            <span className="font-semibold">
              9. KİŞİSEL VERİLERİN AKTARILMASI VE KİŞİSEL VERİLERİN ÜÇÜNCÜ
              KİŞİLER TARAFINDAN İŞLENMESİ
            </span>
          </p>
          Şirket, Kişisel Veri İşleme amaçları doğrultusunda gerekli önlemleri
          alarak Kişisel Verileri yurtiçinde ve/veya yurtdışında bulunan üçüncü
          bir gerçek ya da tüzel kişiye KVK Düzenlemelerine uygun şekilde
          aktarabilir. Bu durumda Şirket, Kişisel Veri aktardığı üçüncü
          kişilerin de işbu Politika’ya uymasını sağlar. Bu kapsamda üçüncü kişi
          ile akdedilen sözleşmelere gerekli koruyucu düzenlemeler eklenir. Her
          türlü Kişisel Veri aktarımı yapılan üçüncü kişilerle akdedilen
          sözleşmelere eklenecek madde ise Veri Sorumlusu İrtibat Kişisinden
          temin edilir. Her bir çalışan, Kişisel Veri aktarımı yapılacak durumda
          işbu Politika’da yer alan süreci kat etmekle yükümlüdür. Veri
          Sorumlusu İrtibat Kişisi tarafından iletilen maddede Kişisel Verilerin
          aktarıldığı üçüncü kişinin değişiklik talep etmesi halinde durum
          derhal çalışan tarafından Veri Sorumlusu İrtibat Kişisine bildirir.
          <p></p>
          <p>
            <span>
              9.1. Türkiye’de Bulunan Üçüncü Kişilere Kişisel Veri Aktarımı
            </span>
          </p>
          <p></p>
          <p>
            <span>
              9.1.1. Kişisel Veriler, KVKK Madde 5.2’de ve yeterli önlemler
              alınmak kaydıyla Madde 6.3’de belirlenen istisnai hallerde Açık
              Rıza olmaksızın yahut diğer hallerde Veri Öznesinin Açık Rızası
              alınmak şartı ile (KVKK Madde 5.1 ve Madde 6.2) Türkiye’de bulunan
              üçüncü kişilere Şirket tarafından aktarılabilir.
            </span>
          </p>
          <p></p>
          <p>
            <span>
              9.1.2. Kişisel Verilerin Türkiye’de bulunan üçüncü kişilere
              aktarımının KVK Düzenlemelerine uygun olmasını sağlamaktan Şirket
              çalışanları ve Veri Sorumlusu İrtibat Kişisi müteselsilen
              sorumludur.
            </span>
          </p>
          <p></p>
          <p>
            <span>
              9.2. Yurt Dışında Bulunan Üçüncü Kişilere Kişisel Veri Aktarımı
            </span>
          </p>
          <p></p>
          <p>
            <span>
              9.2.1. Kişisel Veriler, KVKK Madde 5.2 ve Madde 6.3’de belirlenen
              istisnai hallerde Açık Rıza olmaksızın yahut diğer hallerde Veri
              Öznesinin Açık Rızası alınmak şartı ile (KVKK Madde 5.1 ve Madde
              6.2) yurt dışında bulunan üçüncü kişilere, Şirket tarafından
              aktarılabilir.
            </span>
          </p>
          <p></p>
          <p>
            <span>
              9.2.2. Kişisel Verilerin KVK Düzenlemelerine uygun olarak Açık
              Rıza alınmaksızın aktarıldığı durumda ayrıca aktarılacağı yabancı
              ülke bakımından aşağıdaki koşullardan birinin varlığı gerekir:
              Kişisel Verilerin aktarıldığı yabancı ülkenin Kurul tarafından
              yeterli korumanın bulunduğu ülkeler statüsünde olması (liste için
              lütfen Kurul’un güncel listesini takip ediniz), Aktarımın
              gerçekleşecek olduğu yabancı ülkenin Kurul’un güvenli ülkeler
              listesinde yer almaması halinde Şirketin ve ilgili ülkedeki Veri
              Sorumlularının yeterli korumanın sağlanacağına ilişkin yazılı
              taahhütte bulunarak Kuruldan izin alması.
            </span>
          </p>
          <p></p>
          <p>
            <span>
              9.2.3. Kişisel Verilerin yurt dışında üçüncü kişilere aktarımının
              KVK Düzenlemelerine uygun olmasını sağlamaktan Şirket çalışanları,
              Komite ve İrtibat Kişisi müteselsilen sorumludur.
            </span>
          </p>
          <p></p>
          <p>
            <span className="font-semibold">
              10. ŞİRKETİN AYDINLATMA YÜKÜMLÜLÜĞÜ
            </span>
          </p>
          10.1. Şirket, KVKK’nın 10. Maddesine uygun olarak, Kişisel Verilerin
          İşlenmesinden önce Veri Öznelerini aydınlatır. Bu kapsamda Şirket,
          Kişisel Verilerin elde edilmesi sırasında Aydınlatma Yükümlülüğünü
          yerine getirir. Aydınlatma Yükümlülüğü kapsamında Veri Öznelerine
          yapılacak olan bildirim sırasıyla şu unsurları içerir: Veri
          Sorumlusunun ve varsa temsilcisinin kimliği, Kişisel Verilerin hangi
          amaçla işleneceği, İşlenen Kişisel Verilerin kimlere ve hangi amaçla
          aktarılabileceği, Kişisel Veri toplamanın yöntemi ve hukuki sebebi,
          Veri Öznelerinin KVKK Madde 11’de sayılan hakları.
          <p></p>
          <p></p>
          <p>
            <span>
              10.2. Şirket, Türkiye Cumhuriyeti Anayasası’nın 20. ve KVKK’nın
              11. Maddesine uygun olarak Veri Öznesinin bilgi talep etmesi
              halinde gerekli bilgilendirmeyi yapar.
            </span>
          </p>
          <p></p>
          <p>
            <span>
              10.3. Veri Özneleri tarafından KVKK Düzenlemelerine uygun olarak
              talep edilmesi halinde Şirket, Veri Öznesinin işlediği Kişisel
              Verilerini Veri Öznesine bildirir.
            </span>
          </p>
          <p></p>
          <p>
            <span>
              10.4. Kişisel Verilerin İşlenmesinden önce gerekli Aydınlatma
              Yükümlülüğünün yerine getirilmesini sağlamaktan ilgili süreci
              takip eden çalışan, Komite ve Veri Sorumlusu İrtibat Kişisi
              müteselsilen sorumludur. Bu kapsamda her bir yeni veri işleme
              sürecinin Komiteye raporlanması amacı ile gerekli KVK Prosedürü
              Veri Sorumlusu İrtibat Kişisi ve Komite tarafından oluşturulur.
            </span>
          </p>
          <p></p>
          <p>
            <span>
              10.5. Veri İşleyenin Şirket haricinde üçüncü bir kişi olması
              halinde, üçüncü kişinin yukarıda belirtilen yükümlülüklere uygun
              davranacağı yazılı bir sözleşme ile Kişisel Veri İşlemeye
              başlanmadan önce üçüncü kişi tarafından taahhüt edilmelidir.
              Üçüncü kişilerin Şirkete Kişisel Veri aktardığı durumlarda
              sözleşmelere eklenecek madde Veri Sorumlusu İrtibat Kişisinden
              temin edilir. Her bir çalışan, Şirkete üçüncü bir kişi tarafından
              Kişisel Veri aktarımı yapılan durumda işbu Politika’da yer alan
              süreci kat etmekle yükümlüdür. Veri Sorumlusu İrtibat Kişisi
              tarafından iletilen maddede Kişisel Verileri aktaran üçüncü
              kişinin değişiklik talep etmesi halinde durum derhal çalışan
              tarafından Veri Sorumlusu İrtibat Kişisi bildirir.
            </span>
          </p>
          <p></p>
          <p>
            <span className="font-semibold">11. VERİ ÖZNELERİNİN HAKLARI</span>
          </p>
          <p></p>
          <p>
            <span>
              11.1. Şirket Kişisel Verisini elinde bulundurduğu Veri Öznelerinin
              aşağıda belirtilen kendileriyle ilgili taleplerine KVK
              Düzenlemelerine uygun şekilde cevap verir: Şirket tarafından
              Kişisel Veri İşlenip İşlenmediği öğrenme, Kişisel Verilerinin
              işlenmesi halinde buna ilişkin bilgi talep etme, Kişisel Verilerin
              işlenme amacını ve bunların amacına uygun kullanılıp
              kullanılmadığını öğrenme, Yurt içinde veya yurt dışında Kişisel
              Verilerin aktarıldığı üçüncü kişileri bilme, Kişisel Verilerin
              Şirket tarafından eksik veya yanlış işlenmiş olması hâlinde
              bunların düzeltilmesini isteme, Amaç, süre ve meşruiyet
              prensipleri dâhilinde değerlendirilmek üzere Kişisel Verilerin
              İşlenmesini gerektiren sebeplerin ortadan kalkması halinde Şirket
              tarafından Kişisel Verilerin Silmesini veya yok edilmesini isteme,
              Şirket tarafından Kişisel Verilerin düzeltilmesi, Silinmesi ya da
              Yok Edilmesi halinde bu işlemlerin Kişisel Verilerin aktarıldığı
              üçüncü kişilere bildirilmesini isteme, İşlenen Kişisel Verilerin
              münhasıran otomatik sistemler vasıtasıyla analiz edilmesi
              durumunda Veri Öznesinin aleyhine bir sonucun ortaya çıkması
              halinde bu sonuca itiraz etme, Kişisel Verilerin kanuna aykırı
              olarak işlenmesi ve bu sebeple Veri Öznesinin zarara uğraması
              hâlinde zararın giderilmesini talep etme. Veri Özneleri haklarını
              kullanmak istediği ve/veya Kişisel Verileri işlerken Şirketin işbu
              Politika kapsamında hareket etmediğini düşündüğü durumlarda
              taleplerini, Şirket internet sitesinde yer alan formu doldurarak
              veya kendi taleplerini Kişisel Verileri Koruma Kurumu tarafından
              belirlenen şartları taşıyacak şekilde oluşturarak aşağıda verilen
              ve zaman zaman değişebilecek olan e-posta adresine, Şirket’e daha
              önce bildirilmiş ve Şirket sistemine kayıtlı olan e-posta
              adresinden gönderebilecekleri e-posta ile (sisteme kayıtlı e-posta
              adresi kontrol edilmelidir) veya güvenli elektronik imzalı veya
              mobil imzalı olarak Şirket kep adresine yahut yine aşağıda yer
              alan ve zaman zaman değişebilecek olan posta adresine ıslak imzalı
              bir dilekçe ile elden ya da noter aracılığıyla teslim edebilirler
              ve ileride bunlara eklenebilecek Kişisel Verileri Koruma Kurumu
              tarafından belirlenen diğer yöntemlerle gönderebilirler. Güncel
              başvuru yöntemleri ve başvuru içeriği başvuru öncesinde mevzuattan
              teyit edilmelidir. Veri Sorumlusu: GREENPAY Elektronik Ticaret A.Ş. 
              E-posta: info@greenpay.com.tr Posta: Orta Mah. Ordu Sk. A Blok No:23A İç Kapı No:21 Kartal / İstanbul
            </span>
          </p>
          <p></p>
          <p>
            <span>
              11.2. Veri Öznelerinin yukarıda sıralanan haklarına ilişkin
              taleplerini yazılı olarak Şirkete iletmeleri durumunda Şirket
              talebin niteliğine göre talebi en geç otuz gün içinde ücretsiz
              olarak sonuçlandırır. Taleplerin Veri Sorumlusu tarafından
              sonuçlandırılmasına ilişkin ayrıca bir maliyet doğması hâlinde
              Kişisel Verileri Koruma Kurulunca belirlenen tarifedeki ücretler
              Veri Sorumlusu tarafından talep edilebilir.
            </span>
          </p>
          <p></p>
          <p>
            <span className="font-semibold">12. VERİ YÖNETİMİ VE GÜVENLİĞİ</span>
          </p>
          <p></p>
          <p></p>
          <p>
            <span>
              12.1. Şirket, KVK Düzenlemeleri kapsamındaki yükümlülüklerini
              yerine getirmek, işbu Politikanın uygulanması için gerekli KVK
              Prosedürlerinin uygulanmasını sağlamak ve denetlemek, bunların
              işleyişine yönelik önerilerde bulunmak üzere Veri Sorumlusu
              İrtibat Kişisi atar ve Komite oluşturur.
            </span>
          </p>
          <p></p>
          <p>
            <span>
              12.2. Kişisel Verilerin işbu Politika ve KVK Prosedürlerine uygun
              şekilde korunmasından ilgili sürece müdahil olan tüm çalışanlar
              müteselsilen sorumludur.
            </span>
          </p>
          <p></p>
          <p>
            <span>
              12.3. Şirket tarafından Kişisel Veri İşleme faaliyetleri
              teknolojik imkanlar ve uygulama maliyetine göre teknik sistemlerle
              denetlenmektedir.
            </span>
          </p>
          <p></p>
          <p>
            <span>
              12.4. Kişisel Veri İşleme faaliyetlerine yönelik teknik konularda
              bilgili personel istihdam edilmektedir.
            </span>
          </p>
          <p></p>
          <p>
            <span>
              12.5. Şirket çalışanları, Kişisel Verilerin korunmasına ve hukuka
              uygun olarak işlenmesine yönelik olarak bilgilendirilmekte ve
              eğitilmektedir.
            </span>
          </p>
          <p></p>
          <p>
            <span>
              12.6. Şirkette Kişisel Verilere erişmesi gereken çalışanların söz
              konusu Kişisel Verilere erişimini sağlamak adına gerekli KVK
              Prosedürü oluşturulur ve bunun oluşturulması ve uygulanmasından
              Veri Sorumlusu İrtibat Kişisi ve Komite müteselsilen sorumludur.
            </span>
          </p>
          <p></p>
          <p>
            <span>
              12.7. Şirket çalışanları, Kişisel Verilere üzerinde yalnızca
              kendilerine tanımlanan yetki dâhilinde ve ilgili KVK Prosedürüne
              uygun olarak erişebilir. Çalışanın yetkisini aşar şekilde yapmış
              olduğu her türlü erişim ve işleme hukuka aykırı olup iş akdinin
              haklı nedenle feshi sebebidir.
            </span>
          </p>
          <p></p>
          <p>
            <span>
              12.8. Şirket çalışanın Kişisel Verilerin güvenliğinin yeterince
              sağlanmadığı şüphesinde olması yahut böyle bir güvenlik açığını
              tespitte bulunması halinde derhal durumu Veri Sorumlusu İrtibat
              Kişisi’ne bildirir.
            </span>
          </p>
          <p></p>
          <p>
            <span>
              12.9. Kişisel Verilerin güvenliğine yönelik detaylı KVK Prosedürü
              Veri Sorumlusu İrtibat Kişisi ve Komite tarafından oluşturulur.
            </span>
          </p>
          <p></p>
          <p>
            <span>
              12.10. Kendisine Şirket cihazı tahsis edilen her kişi, kendi
              kullanımına tahsis edilen cihazlarının güvenliğinden sorumludur.
            </span>
          </p>
          <p></p>
          <p>
            <span>
              12.11. Her Şirket çalışanı veya Şirket bünyesinde çalışan kişi
              kendi sorumluluk alanında yer alan fiziki dosyaların güvenliğinden
              sorumludur.
            </span>
          </p>
          <p></p>
          <p>
            <span>
              12.12. KVK Düzenlemeleri kapsamında Kişisel Verilerin güvenliği
              için talep edilen veya ek olarak talep edilecek olan güvenlik
              önlemleri olması durumunda tüm çalışanlar ek güvenlik önlemlerine
              uymak ve bu güvenlik önlemlerinin sürekliliğini sağlamak ile
              yükümlüdür.
            </span>
          </p>
          <p></p>
          <p>
            <span>
              12.13. Şirkette Kişisel Verilerin güvenli ortamlarda saklanması
              için teknolojik gelişmelere uygun olarak virüs koruma sistemleri
              ve güvenlik duvarlarını içeren yazılımlar ve donanımlar
              kurulmaktadır.
            </span>
          </p>
          <p></p>
          <p>
            <span>
              12.14. Şirkette Kişisel Verilerin kaybolmasını yahut zarar
              görmesini engellemek üzere yedekleme programları kullanılmakta ve
              yeterli düzeyde güvenlik önlemleri alınmaktadır.
            </span>
          </p>
          <p></p>
          <p>
            <span>
              12.15. Şirkette Kişisel Verilerin yer aldığı belgeler kriptolu
              (şifreli) sistemlerle korunması için gerekli önlemler alınacaktır.
              Bu kapsamda, Kişisel Veriler ortak alanlarda ve masaüstünde
              saklanmayacaktır. Kişisel Verilerin yer aldığı dosya ve klasörler
              vb. belgeler masaüstüne veya ortak klasöre taşınmayacak, Veri
              Sorumlusu İrtibat Kişisinin önceden yazılı onayı alınmadan Şirket
              bilgisayarlarındaki bilgiler USB vb. başka bir aygıta
              aktarılamayacak, Şirket dışına çıkartılamayacaktır.
            </span>
          </p>
          <p></p>
          <p>
            <span>
              12.16. Komite, Yönetim Kurulu ile birlikte Şirket içerisinde
              bulunan tüm Kişisel Verilerin korunmasına yönelik teknik ve idari
              önlemleri almak, gelişmeleri ve idari faaliyetleri sürekli takip
              etmekle ve gerekli KVK Prosedürlerini hazırlayarak Şirket
              içerisinde duyurmak ve bunlara uyulmasını sağlamak ve denetlemekle
              yükümlüdür. Bu kapsamda, Komite ve Veri Sorumlusu İrtibat Kişisi
              çalışanların farkındalığını artırmak üzere gerekli eğitimleri
              düzenler.
            </span>
          </p>
          <p></p>
          <p>
            <span>
              12.17. Şirket içerisindeki bir departman Özel Nitelikli Kişisel
              Veri İşliyorsa, bu departman, Komite tarafından işledikleri
              Kişisel Verilerin önemi, güvenliği ve gizliliği hakkında
              bilgilendirilecek ve ilgili departman Komite talimatlarına uygun
              hareket edecektir. Özel Nitelikli Kişisel Verilere erişim yetkisi
              yalnızca sınırlı çalışanlara verilecek ve bunların listesi ve
              takibi Komite tarafından yapılacaktır.
            </span>
          </p>
          <p></p>
          <p>
            <span>
              12.18. Şirket içerisinde işlenen Kişisel Verilerin tamamı Şirket
              tarafından “Gizli Bilgi” olarak kabul edilir.
            </span>
          </p>
          <p></p>
          <p>
            <span>
              12.19. Şirket çalışanları, Kişisel Verilerin güvenliğine ve
              gizliliğine ilişkin yükümlülüklerinin, iş ilişkisinin sona
              ermesinden sonra da devam edeceği konusunda bilgilendirilmiş ve
              Şirket çalışanlarından bu kurallara uymaları yönünde taahhüt
              alınmıştır.
            </span>
          </p>
          <p></p>
          <p>
            <span className="font-semibold">13. DENETİM</span>
          </p>
          Şirket, işbu Politika ve KVK Düzenlemelerine Şirketin tüm çalışanları,
          departmanları ve yüklenicilerinin uygun hareket ettiğini düzenli
          olarak hiçbir ön bildirimde bulunmaksızın her zaman ve re’sen
          denetleme hakkını haizdir ve bu kapsamda gerekli rutin denetimleri
          yapar. Komite ve Veri Sorumlusu İrtibat Kişisi bu denetimlere dair KVK
          Prosedürü oluşturur, Yönetim Kurulu onayına sunar ve anılan prosedürün
          uygulanmasını sağlar.
        </div>
      </div>
    </div>
  );
};
export default KVKKPolitikasi;

import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { sendOtp, loginWithOtp, reset } from "redux/subreseller/auth/customerSlice";
import { toast } from "react-toastify";
import Heading from "components/Typography/Header";
import Button from "components/Button";
import CircularCountdown from "components/CircularCountDown";

const Login = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const dispatch = useDispatch();
  const [formValues, setFormValues] = useState({
    phoneNumber: "",
    otp: "",
  });
  const { isError, isSuccess, message, isLoading, isSendOTP } = useSelector(
    (state) => state.subresellerAuth
  );

  useEffect(() => {
    if (isSendOTP) {
      setStep(2);
    } else {
      setStep(1);
    }
  }, [isSendOTP])

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }
    if (isSuccess) {
      toast.success(message);
      setTimeout(() => {
        navigate("/subreseller");
      }, 1000);
    }
    return () => {
      dispatch(reset());

    };
  }, [isError, isSuccess, message, dispatch]);



  const phoneRegex = /^(\+90|0)?[0-9]{10}$/;

  const phoneForm = Yup.object().shape({
    phoneNumber: Yup.string()
      .matches(phoneRegex, "Geçerli bir telefon numarası giriniz.")
      .required("Telefon numarası gereklidir."),
  });

  const otpForm = Yup.object().shape({
    otp: Yup.string().required("OTP kodu gereklidir."),
  });

  const validationSchema = step === 1 ? phoneForm : otpForm;

  const handleSubmit = async (values) => {
    setFormValues(values);
    if (step === 1) {
      await dispatch(sendOtp({ phone: values.phoneNumber }));

    } else {
      await dispatch(loginWithOtp({ phone: values.phoneNumber, otp: values.otp }));
    }
  };


  return (
    <div className="flex flex-1 flex-col h-full min-h-screen md:justify-center mt-12 items-center">
      <Heading level={4} className="text-primary">
        Alt Kurumsal Giriş
      </Heading>
      <div className="space-y-10 mt-10 ">
        <Formik
          initialValues={formValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched }) => (
            <Form className="space-y-4">
              <div className="w-[300px]">
                {step === 1 ? (
                  <>
                    <span className="text-white-100 font-medium ">
                      Telefon Numarası
                    </span>
                    <Field
                      autoComplete="off"
                      name="phoneNumber"
                      type="text"
                      className="focus:outline-none w-full h-10 bg-transparent border-b border-secondary text-white-100"
                    />
                    {errors.phoneNumber && touched.phoneNumber ? (
                      <ErrorMessage
                        component="span"
                        className="text-red-500 my-2"
                        name="phoneNumber"
                      />
                    ) : null}
                  </>
                ) : (
                  <div>
                    <span className="text-white-100 font-medium">
                      OTP Kodu
                    </span>
                    <Field
                      autoComplete="off"
                      name="otp"
                      type="text"
                      className="focus:outline-none w-full h-10 bg-transparent border-b border-secondary text-white-100"
                    />
                    <div className="flex justify-center py-4">
                      <CircularCountdown />
                    </div>
                    {errors.otp && touched.otp ? (
                      <ErrorMessage
                        component="span"
                        className="text-red-500 my-2"
                        name="otp"
                      />
                    ) : null}
                  </div>
                )}
              </div>
              <Button loading={isLoading} size="small" className="w-full mt-4">
                {step === 1 ? "Kod Gönder" : "Giriş Yap"}
              </Button>
            </Form>
          )}
        </Formik>
        <div className="flex gap-4">
          <Link to="/login/subreseller" className="bg-secondary hover:scale-105 transition-all flex items-center text-sm justify-center text-white-100 font-medium text-center h-10 rounded-[24px] w-full">Alt Kurumsal Giriş</Link>
          <Link to="/login/reseller" className="bg-secondary hover:scale-105 transition-all flex items-center justify-center text-white-100 text-sm font-medium text-center h-10 rounded-[24px] w-full">Kurumsal Giriş</Link>
        </div>
      </div>
    </div >
  );
};

export default Login;

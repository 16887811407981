import React, {useEffect, useState} from "react";
import * as XLSX from "xlsx";
import Payments from "components/Panel/Admin/Payments";
import {useDispatch, useSelector} from "react-redux";
import {getPayments} from "redux/admin/payments/paymentsSlice";
import {useNavigate} from "react-router-dom";
import Pagination from "components/Pagination";
import {Link} from "react-router-dom";
import Button from "components/Button";
import {useDebounce} from "use-debounce";

const PaymentsContainer = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [search, setSearch] = useState();
    const [withoutPayment, setWithoutPayment] = useState(false);
    const [debouncedSearch] = useDebounce(search, 500);
    const {data} = useSelector((state) => state.adminPayments.payments);
    const {total, pages, page, per_page} = useSelector((state) => state.adminPayments.payments);


    useEffect(() => {
        dispatch(getPayments({page: page, per_page: 15, search: debouncedSearch, without_order: withoutPayment}));
    }, [dispatch, debouncedSearch, withoutPayment]);

    const handlePageChange = (newPage) => {
        dispatch(getPayments({page: newPage, per_page: 15, search: debouncedSearch, without_order: withoutPayment}));
    };

    const exportToExcel = async () => {
        dispatch(getPayments());
        const ws = XLSX.utils.json_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Ödemeler");

        // Dosyayı kaydet
        XLSX.writeFile(wb, "greenpay-odemeler.xlsx");
    };

    return (<div className="bg-black min-h-screen">
        <div className="p-4 flex items-center justify-between">
            <div className="flex gap-4">
                {" "}
                <h2 className="text-xl font-semibold text-white-100">Ödemeler</h2>
                <span
                    onClick={() => navigate(0)}
                    className="bg-secondary text-white-100 px-2 py-1 rounded-md cursor-pointer"
                >
            Yenile
          </span>
                <span
                    onClick={exportToExcel}
                    className="bg-primary text-white-100 px-2 py-1 rounded-md cursor-pointer"
                >
            Excel'e Aktar
          </span>
            </div>
            <Link to="/admin/payments/create">
                {" "}
                <Button size="small">Ödeme Oluştur</Button>
            </Link>
        </div>
        <div className="px-4 flex items-center gap-14 max-w-[700px]">
            <input
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                type="text"
                className="border border-gray-500  rounded-lg p-2"
                placeholder="Ad Soyad, Telefon Numarası, TCKN, Payment Key"
            />
            <div className="flex items-center gap-2">
                <div className="inline-flex items-center">
                    <label className="relative flex items-center p-3 rounded-full cursor-pointer"
                           htmlFor="checkbox">
                        <input type="checkbox"
                               className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-white-100 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:bg-primary checked:before:bg-primary hover:before:opacity-10"
                               id="checkbox"
                                 checked={withoutPayment}
                                    onChange={() => setWithoutPayment(!withoutPayment)}
                        />
                        <span
                            className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5" viewBox="0 0 20 20"
                           fill=""
                           stroke="currentColor" stroke-width="1">
                        <path fill-rule="evenodd"
                              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                              clip-rule="evenodd"></path>
                      </svg>
    </span>
                    </label>
                    <label htmlFor="checkbox" className="text-white-100">Sipariş Oluşturulmamış</label>
                </div>

            </div>
        </div>
        <Payments data={data}/>

        <Pagination
            currentPage={page}
            totalPages={pages}
            onPageChange={handlePageChange}
        />
    </div>);
};

export default PaymentsContainer;

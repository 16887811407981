import React from 'react'
import { Link } from 'react-router-dom'
import OrderIndicator from 'components/OrderIndicator'
import moment from 'moment'
import 'moment/locale/tr'

moment.locale("tr");


const Orders = ({ data }) => {
  return (
    <div className="overflow-x-auto p-4">
      <table className="min-w-full divide-y-2 divide-gray-200 text-sm">
        <thead className="">
          <tr>
            <th className="whitespace-nowrap text-left px-4 py-2 font-medium text-white-100">
              Bigo Kullanıcı Adı
            </th>

            <th className="whitespace-nowrap text-left px-4 py-2 font-medium text-white-100">
              Yatırılan Tutar
            </th>
            <th className="whitespace-nowrap text-left px-4 py-2 font-medium text-white-100">
              Elmas Miktarı
            </th>
            <th className="whitespace-nowrap text-left px-4 py-2 font-medium text-white-100">
              İşlem Tarihi
            </th>
            <th className="whitespace-nowrap text-left px-4 py-2 font-medium text-white-100">
              İşlem Durumu
            </th>
            <th className="whitespace-nowrap text-left px-4 py-2 font-medium text-white-100">
              İşlem
            </th>
          </tr>
        </thead>

        <tbody className="divide-y divide-gray-200">
          {data?.map((data) => (
            <tr key={data.id}>
              <td className="whitespace-nowrap px-4 py-2 font-medium text-white-100">
                {data.bigo_username}
              </td>

              <td className="whitespace-nowrap px-4 py-2 text-white-100">
                {data.payment.amount_try} ₺
              </td>
              <td className="whitespace-nowrap px-4 py-2 text-white-100">
                {data.amount_diamonds}
              </td>
              <td className="whitespace-nowrap px-4 py-2 text-white-100">
                {moment(data.created_at).format('Do MMMM YYYY, H:mm:ss')}
              </td>
              <td className="whitespace-nowrap px-4 py-2 text-white-100">
                {data.status === "completed" ? (
                  <OrderIndicator color="green">Aktarım Yapıldı.</OrderIndicator>
                ) : data.status === "pending_transfer" ? (
                  <OrderIndicator color="yellow">Aktarım Bekliyor.</OrderIndicator>
                ) : data.status === "failed" ? (
                  <OrderIndicator color="red">
                    Aktarım Reddedildi.
                  </OrderIndicator>
                ) : (
                  <OrderIndicator color="red">No Data</OrderIndicator>
                )}
              </td>
              <td className="whitespace-nowrap px-4 py-2">
                <Link
                  to={`/customer/orders/detail/${data.id}`}
                  className="inline-block rounded bg-indigo-600 px-4 py-2 text-xs font-medium text-white-100 hover:bg-indigo-700"
                >
                  Detaylı Gör
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Orders;

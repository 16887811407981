import React, { useEffect } from "react";
import { detailPayment } from "redux/admin/payments/paymentsSlice";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { detailReseller, detailSubreseller } from "redux/admin/users/usersSlice";
import moment from "moment";
import "moment/locale/tr";
import OrderIndicator from "components/Panel/Admin/OrderIndicator";

moment.locale("tr");

const PaymentsDetailContainer = () => {
  const dispatch = useDispatch();

  const { id } = useParams();
  const { payment: data } = useSelector(
    (state) => state.adminPayments
  );

  const { reseller, subreseller } = useSelector(
    (state) => state.adminUsers
  );

  useEffect(() => {
    dispatch(detailPayment({ search: id }));
    if (data) {
      if (data?.order?.creator_type === "reseller") {
        dispatch(detailReseller({ search: data.order.creator_id }));

      }
      if (data?.order?.creator_type === "subreseller") {
        dispatch(detailSubreseller({ search: data.order.creator_id }));

      }

    }
  }, [dispatch, id, data]);


  return (
    <div className="shadow-md rounded px-8 pt-6 pb-8 mb-4">
      <h2 className="text-2xl font-semibold text-white-100 mb-4 flex items-center gap-4">
        Ödeme Detayları #{data.id}
      </h2>
      <hr className="mb-4" />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
        <div className="bg-gray-900 p-4 rounded shadow space-y-2">
          <h3 className="text-xl font-semibold text-white-100 mb-4 flex items-center gap-4">
            İşlem Bilgileri{" "}
          </h3>

          <p className="text-white-100">
            <span className="font-semibold">Ad Soyad:</span>{" "}
            {data.full_name}
          </p>
          <p className="text-white-100">
            <span className="font-semibold">Yatırılan Tutar:</span>{" "}
            {data.amount_try} ₺
          </p>
          <p className="text-white-100">
            <span className="font-semibold">Ödemenin Bankaya Ulaşma Tarihi:</span>{" "}
            {moment(data?.received_at).utcOffset('+03:00').format('Do MMMM YYYY, H:mm:ss')}
          </p>
          <p className="text-white-100">
            <span className="font-semibold">Ödemenin Oluşturuma Tarihi:</span>{" "}
            {moment(data?.created_at).utcOffset('+03:00').format('Do MMMM YYYY, H:mm:ss')}
          </p>
          <p className="text-white-100">
            <span className="font-semibold">Ödeme Güncellenme Tarihi:</span>{" "}
            {moment(data?.updated).utcOffset('+03:00').format('Do MMMM YYYY, H:mm:ss')}
          </p>
          <p className="text-white-100">
            <span className="font-semibold">EFT Sorgu No:</span>{" "}
            {data.reference1}
          </p>
          <p className="text-white-100">
            <span className="font-semibold">TCKN:</span>{" "}
            {data.tckn}
          </p>
          <p className="text-white-100">
            <span className="font-semibold">EFT Açıklaması:</span>{" "}
            {data.reference4}
          </p>

        </div>
        <div className="bg-gray-900 p-4 rounded shadow space-y-2">
          <h3 className="text-xl font-semibold text-white-100 mb-4 flex items-center gap-4">
            Banka Bilgileri{" "}
          </h3>

          <p className="text-white-100">
            <span className="font-semibold">Banka Adı:</span>{" "}
            {data?.bank_account?.name}
          </p>
          <p className="text-white-100">
            <span className="font-semibold">IBAN:</span>{" "}
            {data?.bank_account?.iban}
          </p>
          <p className="text-white-100">
            <span className="font-semibold">Hesap Adı:</span>{" "}
            {data?.bank_account?.holder_name}
          </p>
          <p className="text-white-100">
            <span className="font-semibold">Code:</span>{" "}
            {data?.bank_account?.code}
          </p>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
        <div className="bg-gray-900 p-4 rounded shadow space-y-2">
          <h3 className="text-xl font-semibold text-white-100 mb-4 flex items-center gap-4">
            Sipariş Bilgileri{" "}
          </h3>

          <p className="text-white-100">
            <span className="font-semibold">Müşteri Adı Soyadı:</span>{" "}
            {data?.order?.customer?.full_name}
          </p>
          <p className="text-white-100">
            <span className="font-semibold">Müşteri Telefon Numarası:</span>{" "}
            {data?.order?.customer?.phone || "Bilinmiyor"}
          </p>
          <p className="text-white-100">
            <span className="font-semibold">Satın Alınan Elmas Sayısı:</span>{" "}
            {data?.order?.amount_diamonds || "Bilinmiyor"}
          </p>
          <p className="text-white-100">
            <span className="font-semibold">Satın Tanımlanma Durumu:</span>{" "}
            {data?.order?.status === "completed" ? (
              <OrderIndicator color="green">
                Aktarım Yapıldı.
              </OrderIndicator>
            ) : data.status === "pending_transfer" ? (
              <OrderIndicator color="yellow">
                Aktarım Bekliyor.
              </OrderIndicator>
            ) : data.status === "failed" ? (
              <OrderIndicator color="red">
                Aktarım Reddedildi.
              </OrderIndicator>
            ) : (
              <OrderIndicator color="red">No Data</OrderIndicator>
            )}
          </p>
        </div>
        <div className="bg-gray-900 p-4 rounded shadow space-y-2">
          <h3 className="text-xl font-semibold text-white-100 mb-4 flex items-center gap-4">
            İşlemi Yapan{" "}
          </h3>

          <p className="text-white-100">
            <span className="font-semibold">Tipi:</span>{" "}
            {(data?.order?.creator_type === "reseller" ? "Bayi" : "Alt Bayi") || "Bilinmiyor"}
          </p>
          <p className="text-white-100">
            <span className="font-semibold">Adı:</span>{" "}
            {(data?.order?.creator_type === "reseller" ? reseller.name : subreseller.name) || "Bilinmiyor"}
          </p>

        </div>
      </div>


    </div>
  );
};

export default PaymentsDetailContainer;

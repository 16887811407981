import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { bankAccounts } from "redux/customer/bank-accounts/bankAccountsSlice";
import Select from "react-select";

const BankLogos = [
  {
    name: "Ziraat Bankası",
    logo: "https://www.ziraatbank.com.tr/SiteAssets/images/logo.png",
  },
  {
    name: "Garanti Bankası",
    logo: "https://www.garantibbva.com.tr/content/experience-fragments/public-website/tr/site/header/master/_jcr_content/root/header/headerdesktop/image.coreimg.svg/1664356028506/logo.svg",
  },
  {
    name: "Akbank",
    logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7b/Akbank_logo.svg/424px-Akbank_logo.svg.png",
  },
  {
    name: "Vakıfbank",
    logo: "https://www.vakifbank.com.tr/img/logo.png",
  },
  {
    name: "Finansbank",
    logo: "https://www.qnbfinansbank.com/_assets/img/logo.png",
  },
  {
    name: "Deniz Bank",
    logo: "https://www.denizbank.com/_assets/img/DenizbankLOGO.svg",
  },
  {
    name: "İş Bankası",
    logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/2/24/T%C3%BCrkiye_%C4%B0%C5%9F_Bankas%C4%B1_logo.svg/2560px-T%C3%BCrkiye_%C4%B0%C5%9F_Bankas%C4%B1_logo.svg.png",
  },
  {
    name: "Yapıkredi",
    logo: "https://assets.yapikredi.com.tr/WebSite/_assets/img/Yapikredi_logo.svg?v6"
  },
  {
    name:"Emlak Katılım",
    logo:"https://www.emlakkatilim.com.tr/Templates/Default/assets/img/logo.svg"
  }
];

function getLogo(bankName) {
  for (let i = 0; i < BankLogos.length; i++) {
    if (BankLogos[i].name === bankName) {
      return BankLogos[i].logo;
    }
  }
  return "Banka adı bulunamadı.";
}

const BankAccounts = ({ amount, next }) => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.customerBankAccounts);

  const options = data?.map((item) => {
    return {
      value: item.iban,
      label: <img src={getLogo(item.name)} width={160} alt={item.name} />,
    };
  });

  const [selectedOption, setSelectedOption] = useState(options[0]);

  useEffect(() => {
    dispatch(bankAccounts());
  }, []);

  return (
    <>
      <div className="w-full md:col-span-2 relative  m-auto md:min-h-screen bg-gray-900 shadow-lg rounded-lg  p-4 md:overflow-y-auto">
        <div className="flex gap-4 items-center my-4">
          <span className="text-2xl bg-primary text-white-100  flex items-center justify-center rounded-full w-10 h-10">
            2
          </span>
          <span className="text-white-100 font-semibold text-lg">
            Havale / EFT
          </span>
        </div>

        <div className="grid grid-cols-1 md:gap-x-12 gap-y-4">
          <span className="text-white-100 font-semibold">Banka Seç:</span>
          <Select
            defaultOptions
            defaultValue={selectedOption}
            onChange={setSelectedOption}
            options={options}
          />
          {selectedOption && (
            <div className="space-y-6 mt-6 w-full">
              <div className="">
                <h2 className="text-white-100 font-semibold">Hesap Adı:</h2>
                <span className="text-white-100">
                  Greenpay Elektronik Ticaret Anonim Şirketi
                </span>
              </div>
              <div className="">
                <h2 className="text-white-100 font-semibold">IBAN No:</h2>
                <span className="text-xs text-gray-200">
                  Bu IBAN'a para göndererek Greenpay'e para yatırabilirsiniz.
                </span>
                <span
                  onClick={() =>
                    navigator.clipboard.writeText(selectedOption.value)
                  }
                  className="text-primary font-medium text-xl block cursor-pointer"
                >
                  {selectedOption.value}
                </span>
              </div>
              <div>
                <h2 className="text-white-100 font-semibold">
                  Hesabımıza Yatırmanız Gereken Net Tutar:
                </h2>
                <span className="text-primary font-semibold text-xl">
                  {amount.toFixed(2)} TL
                </span>
              </div>
              <span className="block px-2 py-1 rounded-md my-4 bg-red-300 text-red-900 text-sm font-semibold">
                Hesabımıza yatırmanız gereken net tutar kadar gönderim yaptığınızdan emin olun. Aksi takdirde para yatırma işleminiz tamamlanmayacaktır.
              </span>
              <button
                className="bg-secondary disabled:bg-blue-400 text-white-100 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="submit"
                onClick={next}
                disabled={!amount}
              >
                Devam Et
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default BankAccounts;

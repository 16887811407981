import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Heading from "components/Typography/Header";
import Button from "components/Button";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  retailCreate,
  reset
} from "redux/reseller/retails/retailsSlice";
import { useCustomSettings } from 'hooks/useCustomSettings'
import Popup from "components/Popup";
import { useUUID } from "hooks/useUUID";

const keyTextMapping = {
  base_diamond_price_usd: "1 Adet Elmas Fiyatı ($)",
  usdtry_exchange_rate: "1 Dolar",
}


const OrderRetailCreateContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [trigger, setTrigger] = useState(0);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const {
    isError,
    isLoading,
    isSuccess,
    message
  } = useSelector((state) => state.resellerRetails);
  const { data } = useSelector(state => state.resellerSettings);
  const uuid = useUUID(trigger);

  const profile = useSelector((state) => state.resellerProfile.profile);
  const diamondPrice = useCustomSettings(data, keyTextMapping, 'base_diamond_price_usd');
  const exchangeRate = useCustomSettings(data, keyTextMapping, 'usdtry_exchange_rate');

  const createOrderForm = Yup.object().shape({
    bigoUsername: Yup.string().required("Bigo kullanıcı adı gerekli"),
    diamondAmount: Yup.number().required("Elmas Miktarı Gerekli")
  });

  const tryAmount = (diamondAmount) => {
    let amount = diamondAmount * diamondPrice?.value * exchangeRate?.value
    amount = Math.ceil(amount / (1 - profile?.retail_commission_rate/100))
    return amount
  }

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }
    if (isSuccess) {
      setIsPopupOpen(true);
      setTrigger((prev) => prev + 1);
    }
    return () => {
      dispatch(reset());
    };
  }, [isSuccess, isError, message, dispatch]);


  const handleSubmit = async (values) => {

    dispatch(
      retailCreate({
        bigo_username: values.bigoUsername,
        diamonds_amount: values.diamondAmount,
        nonce: uuid
      })
    );
    
  };


  return (
    <div className="p-4 ">
      <Heading className="text-white-100" level={5}>
        ID ile Elmas Yükle
      </Heading>
      <div className="p-4 bg-gray-900 my-4 rounded-xl min-h-screen">
    
        <div className="grid grid-cols-1 md:grid-cols-1 gap-4">
       
          <div className="p-4 bg-black md:h-screen">
            <Formik
              initialValues={{
                bigoUsername: "",
                diamondAmount: "",

              }}
              validationSchema={createOrderForm}
              onSubmit={handleSubmit}
            >
              {({ errors, touched, setFieldValue, values }) => {


                return (
                  (
                    <Form className="space-y-4">
                       <div className="text-white-100 font-medium mb-4">Kullanılabilir Toplam Bakiye: {profile?.balance_try + profile?.allowance_try} ₺</div>
                      <div className="">
                        <span className="text-white-100 font-medium">
                         Bigo Kullanıcı Adı 
                        </span>
                        <Field
                          name="bigoUsername"
                          type="text"
                          className="w-full font-medium bg-white-100 rounded-lg appearance-none focus:outline-none  p-2 mt-2"
                        />
                        <ErrorMessage
                          name="bigoUsername"
                          component="div"
                          className="text-red-500"
                        />
                      </div>

                      <div className="">
                        <span className="text-white-100 font-medium">
                         Elmas Miktarı
                        </span>
                        <Field
                          name="diamondAmount"
                          type="number"
                          className="w-full font-medium bg-white-100 rounded-lg appearance-none focus:outline-none  p-2 mt-2"
                        />
                        <ErrorMessage
                          name="diamondAmount"
                          component="div"
                          className="text-red-500"
                        />
                      </div>

                      <Button
                        type="submit"
                        size="small"
                        loading={isLoading}
                        className="bg-primary"
                      >
                        Sipariş Oluştur
                      </Button>
                      <div className="flex flex-col space-y-4 mt-4">
                        <span className="block mt-2 text-primary font-medium">Komisyon: %{profile.retail_commission_rate}</span>
                        <span className="block mt-2 text-primary font-medium">Net Tutar: {tryAmount(values.diamondAmount)}₺</span>
                        <span className="block mt-2 text-primary font-medium">İşlem Sonu Toplam Bakiyeniz: {(profile?.balance_try + profile?.allowance_try) - tryAmount(values.diamondAmount)}₺</span>
                      </div>
                    </Form>
                  )
                )
              }}
            </Formik>
           
          </div>
       

        </div>
      </div>
      <Popup isOpen={isPopupOpen} onClose={() => setIsPopupOpen(false)}>
        <div>
          <Heading className="text-primary text-center" level={5}>
            Başarılı
          </Heading>
          <p className="text-white-100 mt-4 text-xl font-semibold">
            Sipariş Başarıyla Oluşturuldu.
          </p>
        </div>
      </Popup>
    </div>
  );
};

export default OrderRetailCreateContainer;

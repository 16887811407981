import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import Sidebar from "components/Panel/Customer/Sidebar";
import { useSelector, useDispatch } from "react-redux";
import { profile } from "redux/customer/profile/profileSlice"
import { Helmet } from "react-helmet";


function DashboardLayout() {
  const dispatch = useDispatch();
  const { user, role } = useSelector((state) => state.customerAuth);
  const location = useLocation();
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(false)

  useEffect(() => {
    setIsSidebarExpanded(false);
  }, [location])

  const handleToggleSidebar = () => {
    setIsSidebarExpanded(!isSidebarExpanded);
  };

  useEffect(() => {
    dispatch(profile());
  }, [dispatch])

  if (!user || role !== "customer") {
    return <Navigate to="/login/customer" />;
  }
  return (
    <div className="min-h-screen flex bg-black">
      <Helmet>
        <title>GreenPay | Kullanıcı Paneli</title>
        <meta name="description" content="GreenPay | Kullanıcı Paneli" />
      </Helmet>
      <Sidebar toggle={isSidebarExpanded} />
      <main className="md:ml-20 w-full">
        <div className="text-white-100 ml-4 my-2 inline-block md:hidden bg-primary px-4 py-1 font-semibold rounded-md " onClick={handleToggleSidebar}>Menü</div>
        <Outlet />
      </main>
      <ToastContainer />
    </div>
  );
}

export default DashboardLayout;

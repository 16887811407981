import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import OrderIndicator from "components/OrderIndicator";
import { useDispatch, useSelector } from "react-redux";
import {
  updateCustomers,
  detailCustomers,
  reset,
} from "redux/admin/users/usersSlice";
import {
  orders as getOrders,
  resetOrders,
} from "redux/admin/orders/ordersSlice";
import Toggle from "components/Toggle";
import { toast } from "react-toastify";
import moment from "moment";
import "moment/locale/tr";
import { BiLoaderCircle } from "react-icons/bi";

moment.locale("tr");

const UserDetail = ({ data, orderData }) => {
  const dispatch = useDispatch();
  const [isBanned, setIsBanned] = useState(data?.is_banned);
  const [fullName, setFullName] = useState("");
  const [phone, setPhone] = useState("");
  const { orders } = useSelector((state) => state.adminOrders);
  const {
    isSuccessUpdateCustomer,
    isErrorUpdateCustomer,
    isLoadingUpdateCustomer,
  } = useSelector((state) => state.adminUsers);

  const handleChangeBan = async () => {
    try {
      await dispatch(
        updateCustomers({ customer_id: data.id, is_banned: !isBanned })
      ).unwrap();
      setIsBanned(!isBanned);
    } catch (error) {
      toast.error("Kullanıcı güncellenirken bir hata oluştu.");
    }
  };

  useEffect(() => {
    setFullName(data.full_name);
    setPhone(data.phone);
  }, [data]);

  useEffect(() => {
    setIsBanned(data?.is_banned);

    if (isSuccessUpdateCustomer) {
      toast.success("Kullanıcı başarıyla güncellendi.");
      dispatch(detailCustomers({ search: data.id }));
    }
    if (isErrorUpdateCustomer) {
      toast.error("Kullanıcı güncellenirken bir hata oluştu.");
    }

    return () => {
      dispatch(reset());
    };
  }, [isSuccessUpdateCustomer, isErrorUpdateCustomer, dispatch, data]);

  const handleChangeUserInfo = () => {
    if (fullName === "") {
      toast.error("Ad Soyad Boş Bırakılamaz");
      return;
    }
    if (phone === "" || phone === null) {
      dispatch(updateCustomers({ customer_id: data.id, full_name: fullName }));
    }else{
      dispatch(updateCustomers({ customer_id: data.id, full_name: fullName, phone:phone }));
    }
  };

  return (
    <div className="bg-white shadow-md min-h-screen rounded px-8 pt-6 pb-8 mb-4">
      <h2 className="text-2xl font-semibold text-white-100 mb-4 flex items-center gap-4">
        Kullanıcı Detayı #{data?.id}
      </h2>
      <hr className="mb-4" />
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <div className="bg-gray-900 p-4 space-y-4 rounded shadow">
          <h3 className="text-xl font-semibold text-white-100 mb-2">
            Kullanıcı Bilgileri
          </h3>
          <p className="text-white-100">
            <span className="font-semibold">Ad Soyad:</span>{" "}
            <input
              className="rounded-md pl-2 text-black focus:outline-none"
              type="text"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
            />
          </p>
          <p className="text-white-100">
            <span className="font-semibold">Telefon Numarası:</span>{" "}
            <input
              className="rounded-md pl-2 text-black focus:outline-none"
              type="text"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </p>

          <p className="text-white-100">
            <span className="font-semibold">Kayıt Tarihi:</span>{" "}
            {moment(data?.created_at).format("LLL")}
          </p>
          <button
            onClick={handleChangeUserInfo}
            disabled={isLoadingUpdateCustomer}
            className="bg-secondary rounded-md px-4 py-1 text-sm text-white-100 font-medium inline-block"
          >
            {isLoadingUpdateCustomer ? (
              <BiLoaderCircle className="animate-spin w-full text-center" />
            ) : (
              "Kaydet"
            )}
          </button>
        </div>

        <div className="bg-gray-900 p-4 rounded shadow">
          <h3 className="text-xl font-semibold text-white-100 mb-2">
            Kullanıcı Durumu
          </h3>
          <div className="space-y-4">
            <p className="text-white-100 flex items-center gap-4">
              <span className="font-semibold">Yasakla:</span>{" "}
              <Toggle isChecked={isBanned} onChange={handleChangeBan} />
            </p>
          </div>
        </div>
        <div className="bg-gray-900 p-4 rounded shadow">
          <h3 className="text-xl font-semibold text-white-100 mb-2">
            Siparişler
          </h3>
          <p className="text-white-100">
            <span className="font-semibold">Sipariş Sayısı:</span>{" "}
            {data?.orders_count}
          </p>
        </div>
      </div>
      {/*

           <h2 className="text-2xl font-semibold text-white-100 my-4 flex items-center gap-4">
            Geçmiş Satın Alımlar ({data?.orders_count})
          </h2>
          <hr className="mb-4" />
          <div className="bg-gray-900 p-4 rounde shadow">
            <div class="overflow-x-auto">
              <table class="min-w-full divide-y-2 divide-gray-200 bg-white text-sm max-w-[30vh]">
                <thead class="ltr:text-left rtl:text-right">
                  <tr>
                  <th class="whitespace-nowrap px-4 py-2 font-medium text-left text-white-100">
                     Ad Soyad
                    </th>
                    <th class="whitespace-nowrap px-4 py-2 font-medium text-left text-white-100">
                      Bigo Kullanıcı Adı
                    </th>
                    <th class="whitespace-nowrap px-4 py-2 font-medium text-left text-white-100">
                      Telefon
                    </th>
                    <th class="whitespace-nowrap px-4 py-2 font-medium text-left text-white-100">
                      Elmas Miktarı
                    </th>
                    <th class="whitespace-nowrap px-4 py-2 font-medium text-left text-white-100">
                      Tutar
                    </th>
                    <th class="whitespace-nowrap px-4 py-2 font-medium text-left text-white-100">
                      Durum
                    </th>
                    <th class="whitespace-nowrap px-4 py-2 font-medium text-left text-white-100">

                    </th>
                    <th class="px-4 py-2"></th>
                  </tr>
                </thead>

                <tbody class="divide-y divide-gray-200">
                  {orderData?.map((order) => (
                    <tr>
                    <td class="whitespace-nowrap px-4 py-2 font-medium text-white-100">
                      {order.customer.first_name} {order.customer.last_name}
                    </td>
                    <td class="whitespace-nowrap px-4 py-2 text-white-100">
                      {order.bigo_username}
                    </td>
                    <td class="whitespace-nowrap px-4 py-2 text-white-100">
                      {order.amount_diamonds}
                    </td>
                    <td class="whitespace-nowrap px-4 py-2 text-white-100">
                      {order.payment.amount_try}
                    </td>
                    <td class="whitespace-nowrap px-4 py-2 text-white-100">100₺</td>
                    <td class="whitespace-nowrap px-4 py-2 text-white-100">
                      {order.status === "pending_transfer" ? (
                        <OrderIndicator color={"yellow"}>Beklemede</OrderIndicator>
                      ) : order.status === "completed" ? (
                        <OrderIndicator color={"green"}>Tamamlandı</OrderIndicator>
                      ) : (
                        <OrderIndicator color={"red"}>İptal Edildi</OrderIndicator>
                      )}
                    </td>
                    <td class="whitespace-nowrap px-4 py-2">
                      <Link
                        to={`/admin/orders/detail/${order.id}`}
                        class="inline-block rounded bg-indigo-600 px-4 py-2 text-xs font-medium text-white-100 hover:bg-indigo-700"
                      >
                        İncele
                      </Link>
                    </td>
                  </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

        */}
    </div>
  );
};

export default UserDetail;

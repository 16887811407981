import Icons from 'components/Icons'
import Feature2Img from 'images/Feature2Img.svg'
import { ReactComponent as Shield } from 'images/icons/shield.svg'
import { ReactComponent as Tick } from 'images/icons/tick.svg'
import Heading from 'components/Typography/Header'
import Paragraph from 'components/Typography/Paragraph'

const FeatureTwo = () => {
    return (
        <section className='grid grid-cols-12 mt-10 grid-flow-dense md:mt-[120px]'>

            <div className='md:col-start-8 md:col-end-13 col-start-1 col-end-13 space-y-[30px]'>
                <Icons> <Shield /> </Icons>
                <Heading level={5} className="text-secondary">Güvenli Yatırımlar</Heading>
                <Heading level={2} className="text-gray-600">Cebinizi Düşünen Akıllı Çözüm</Heading>
                <Paragraph className="text-black">GreenPay, kullanıcılarına e-pin alımlarında kolaylık ve güven sunarak minimum kolaylık, maksimum verim imkanı sunar.</Paragraph>
                <div className='space-y-[20px]'>
                    <div className='flex gap-[10px] items-center'><Tick /> <Paragraph>Risksiz Yatırım Seçenekleri</Paragraph></div>
                    <div className='flex gap-[10px] items-center'><Tick /> <Paragraph>Karlı E-Pin Alma</Paragraph></div>
                </div>
            </div>
            <div className='md:col-start-1 md:col-end-7 col-start-1 col-end-13 mt-8 md:mt-0 flex items-center'>
                <img
                    src={Feature2Img}
                    alt="Picture of the Feature2Img"
                    width={540}
                    height={540}
                />
            </div>
        </section>
    )
}

export default FeatureTwo
import React, {useEffect, useState} from 'react'
import Orders from 'components/Panel/Subreseller/Orders'
import { useNavigate } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { orders } from 'redux/subreseller/orders/ordersSlice';
import Pagination from 'components/Pagination';


const OrdersContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data } = useSelector(state => state.subresellerOrders.orders);
  const [intervalId, setIntervalId] = useState(null);
  const { total, pages, page, per_page } = useSelector(state => state.subresellerOrders.orders);
  useEffect(() => {
    const fetchOrders = () => {
      dispatch(orders({ page: page, per_page: 30 }))
    };
    fetchOrders();
    const id = setInterval(fetchOrders, 10000);
    setIntervalId(id);

    return () => {
      clearInterval(intervalId);
    };
  }, [dispatch, page]);

  const handlePageChange = (newPage) => {
    clearInterval(intervalId);
    dispatch(orders({ page: newPage, per_page: 30 }))
  };

  return (
    <div className='bg-black min-h-screen'>
      <div className='p-4 flex gap-4'>
        <h2 className='text-xl font-semibold text-white-100'>Gelen Dekontlar</h2>
        <span onClick={() => navigate(0)} className='bg-secondary text-white-100 px-2 py-1 rounded-md cursor-pointer'>Yenile</span>
      </div>
      <Orders data={data} />
      <Pagination currentPage={page} totalPages={pages} onPageChange={handlePageChange} />
    </div>
  )
}

export default OrdersContainer

import React from 'react'
import { Helmet } from 'react-helmet'
import ResellerLoginContainer from 'containers/panel/reseller/login-container'
const CustomerLoginPage = () => {
  return (
    <>
     <Helmet>
      <title>Kurumsal Giriş | Greenpay</title>
    </Helmet>
    <ResellerLoginContainer/>
    </>
  )
}

export default CustomerLoginPage
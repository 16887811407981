import React, { useState } from "react";
import "./FlipCard.css";
import Popup from "components/Popup";
import { FaCopy } from "react-icons/fa";
import useCopyToClipboard from "hooks/useCopyToClipboard";
import { IoMdClose } from "react-icons/io";

const BankLogos = [
  {
    name: "Ziraat Bankası",
    logo: "https://www.ziraatbank.com.tr/SiteAssets/images/logo.png",
  },
  {
    name: "Garanti Bankası",
    logo: "https://www.garantibbva.com.tr/content/experience-fragments/public-website/tr/site/header/master/_jcr_content/root/header/headerdesktop/image.coreimg.svg/1664356028506/logo.svg",
  },
  {
    name: "Akbank",
    logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7b/Akbank_logo.svg/424px-Akbank_logo.svg.png",
  },
  {
    name: "Vakıfbank",
    logo: "https://www.vakifbank.com.tr/img/logo.png",
  },
  {
    name: "Finansbank",
    logo: "https://www.qnbfinansbank.com/_assets/img/logo.png",
  },
  {
    name: "Deniz Bank",
    logo: "https://www.denizbank.com/_assets/img/DenizbankLOGO.svg",
  },
  {
    name: "İş Bankası",
    logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/2/24/T%C3%BCrkiye_%C4%B0%C5%9F_Bankas%C4%B1_logo.svg/2560px-T%C3%BCrkiye_%C4%B0%C5%9F_Bankas%C4%B1_logo.svg.png",
  },
  {
    name: "Yapıkredi",
    logo: "https://assets.yapikredi.com.tr/WebSite/_assets/img/Yapikredi_logo.svg?v6"
  },
  {
    name:"Emlak Katılım",
    logo:"https://www.emlakkatilim.com.tr/Templates/Default/assets/img/logo.svg"
  }
];

function getLogo(bankName) {
  for (let i = 0; i < BankLogos.length; i++) {
    if (BankLogos[i].name === bankName) {
      return BankLogos[i].logo;
    }
  }
  return "Banka adı bulunamadı.";
}

function FlipCard({ data }) {
  const [showPopup, setShowPopup] = useState(false);
  const [isCopied, copy] = useCopyToClipboard();
  const [isCopiedHolder, copyHolder] = useCopyToClipboard();
  const [isFlipped, setIsFlipped] = useState(false);

  const handleMouseOver = () => setIsFlipped(true);
  const handleMouseOut = () => setIsFlipped(false);

  const handlePopup = () => {
    setShowPopup(!showPopup);
  };

  return (
    <>
      <div
        className={`flip-card ${isFlipped ? 'flip' : ''}`}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
      >
        <div className="flip-card-inner box-shadow">
          <div className="flip-card-front">
            <img src={getLogo(data.name)} width={200} />

          </div>
          <div className="flip-card-back">
            <p className="text-xl text-white-200 font-semibold">{data.name}</p>

            <h2 onClick={handlePopup} className="bg-secondary cursor-pointer font-medium text-sm hover:scale-105 transition-all text-white-100 px-4 md:py-1 py-2 rounded-md mt-4">Hesap Bilgileri</h2>
          </div>
        </div>

      </div>
      <Popup isOpen={showPopup} onClose={handlePopup}>
        <div onClick={handlePopup} className="border border-primary popup-shadow absolute -top-3 -right-3 bg-gray-700 rounded-full p-1 shadow-md cursor-pointer hover:scale-105 transition-all"><IoMdClose className="text-white-100 text-2xl" /></div>
        <div className="flex flex-col md:flex-row justify-between gap-4 py-4">
          <div className="bg-white-100 rounded-md flex items-center justify-center px-2 py-1 md:py-0">
            <img src={getLogo(data.name)} width={300} />
          </div>
          <div className="space-y-4 relative">
            <p className="text-white-100 text-3xl text-base md:ml-3 font-medium ">{data.name}</p>
            <p className="text-white-100 md:text-xl text-base md:ml-3 font-medium">ALICI  {isCopiedHolder && <p className="bg-green-200 text-green-800 inline-block rounded-md text-xs px-2 md:ml-3 py-1 absolute flex items-center gap-4">Kopyalandı</p>} <br /> <span className={`text-base ${isCopiedHolder && 'text-primary'} `}>{data.holder_name} </span> <FaCopy onClick={() => copyHolder(data.holder_name)} className="inline-block text-base cursor-pointer hover:text-primary transition-all" /></p>
            <p className="text-white-100 md:text-xl text-base md:ml-3 font-medium">IBAN  {isCopied && <p className="bg-green-200 text-green-800 inline-block rounded-md text-xs px-2 md:ml-3 py-1 absolute flex items-center gap-4">Kopyalandı</p>} <br /> <span className={`text-base ${isCopied && 'text-primary'} `}>{data.iban} </span> <FaCopy onClick={() => copy(data.iban)} className="inline-block text-base cursor-pointer hover:text-primary transition-all" /></p>

          </div>
        </div>
      </Popup>
    </>
  );
}

export default FlipCard;

import React, { useEffect, useState, useRef } from "react";
import NotificationSound from "notification/sound.mp3";

import { useSelector, useDispatch } from "react-redux";
import useCopyToClipboard from "hooks/useCopyToClipboard";
import { useNavigate } from "react-router";
import { FaCopy } from "react-icons/fa";
import {
  orderFinalize,
  reset,
  orders as getOrders,
} from "redux/reseller/orders/ordersSlice";

import moment from "moment";
import "moment/locale/tr";
import { toast } from "react-toastify";


moment.locale("tr");

const Orders = ({ data,inputSearch,setInputSearch,handleUserSearch }) => {
  const audioRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isCopied, copy] = useCopyToClipboard();

  const { isFinalizeSuccess, isFinalizeError, isFinalizeLoading, message } =
    useSelector((state) => state.resellerOrders);
  const [prevOrderCount, setPrevOrderCount] = useState(null);
  const [ordersData, setOrdersData] = useState([]);
  const { profile } = useSelector((state) => state.resellerProfile);
  useEffect(() => {
    setOrdersData(data);
  }, [data]);



  useEffect(() => {
    if (prevOrderCount !== null && data?.length > prevOrderCount) {
      audioRef.current.play();
    }
    setPrevOrderCount(data?.length);
  }, [ordersData, prevOrderCount]);

  useEffect(() => {
    if (isFinalizeSuccess) {
     navigate(0);
    }
    if (isFinalizeError) {
     toast.error(message)
    }
    return () => {
      dispatch(reset());
    };
  }, [isFinalizeSuccess, isFinalizeError]);

  useEffect(()=> {
    if(isCopied===true){
      toast.success("Bigo Kullanıcı Adı Kopyalandı",{autoClose:3000});

    }
  },[isCopied])

  const [filter, setFilter] = useState("pending");
  const [search, setSearch] = useState("");

  const handleFilterChange = (value) => {
    setFilter(value);
  };

  const handleSearchChange = (event) => {
    setInputSearch(event.target.value);

  };

  const filteredData = (ordersData || []).filter(
    (item) =>
      item.status.includes(filter) &&
      item.customer.full_name.toLowerCase().includes(search.toLowerCase())
  );

  const handleFinalize = (id, status) => {
    dispatch(orderFinalize({ order_id: id, status: status }));
  };


  return (
    <>
      <audio ref={audioRef} src={NotificationSound} />
      <div className="flex flex-col md:flex-row md:justify-between">
        <input
          type="text"
          className="mx-4 px-2 py-1 rounded-md"
          placeholder="Kullanıcı Ara"
          value={inputSearch}
          onChange={handleSearchChange}
        />
        <div className="mx-4 mt-4 md:mt-0 text-white-100">
          <div className="flex gap-2 justify-between ">
            <div
              onClick={() => handleFilterChange("pending")}
              className="bg-yellow-50 text-yellow-800 px-2 py-1 rounded-md cursor-pointer"
            >
              Onay Bekleyenler
            </div>
            <div
              onClick={() => handleFilterChange("completed")}
              className="bg-green-50 text-green-700 rounded-md px-2 py-1 cursor-pointer"
            >
              Onaylananlar
            </div>
          </div>
        </div>
      </div>
      <div className="overflow-x-auto p-4 grid grid-cols-1 md:grid-cols-4 gap-4">
        {filteredData.map((data) => (
          <div
            key={data.id}
            className={`flex bg-gray-900 flex-col p-3 text-white-100 rounded-md ${data?.retailreseller_id && "border border-primary" }`}
          >

            <h2>Ad Soyad: {data.payment.full_name}</h2>
            <h2 className="flex items-center gap-2">Bigo Kullanıcı Adı: {data.bigo_username} <FaCopy onClick={()=>copy(data.bigo_username)} className="inline cursor-pointer"/></h2>
            <h2>Yatırılan Tutar: {Intl.NumberFormat("tr-TR",{currency:"TRY",style:"currency"}).format(data.payment.amount_try)}</h2>
            <h2>Elmas Miktarı: {Intl.NumberFormat("tr-TR").format(data.amount_diamonds)}</h2>
            <h2>Sorgu No: {data.payment.reference1}</h2>
            <h2>
              {data.subreseller === null
                ? ""
                : "Alt Bayi Adı:" + data.subreseller.name}
            </h2>
            {data?.retailreseller !== null && profile.id !== data?.retailreseller_id && (
              <h2>Elmas Sipariş Eden Bayi: {data?.retailreseller?.name}</h2>
            )}
            <h2>İşlem Tarihi: {moment(data.created_at).format("LLL")}</h2>
            {filter === "pending" && (
              <div className="flex mt-4">
                <button
                  onClick={() => handleFinalize(data.id, "completed")}
                  disabled={isFinalizeLoading}
                  className="text-white-100 cursor-pointer bg-primary px-2 py-2 flex items-center justify-center w-full"
                >
                  Onayla
                </button>
               {
                data?.retailreseller !== null && profile.id !== data?.retailreseller_id && (
                  <button
                  disabled={isFinalizeLoading}
                  onClick={() => handleFinalize(data.id, "failed")}
                  className="text-white-100 cursor-pointer bg-red-500 px-2 py-2 flex items-center justify-center w-full"
                >
                  Reddet
                </button>
                )
               }
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default Orders;

/*

 <table className="min-w-full divide-y-2 divide-gray-200 text-sm">
        <thead className="">
          <tr>
            <th className="whitespace-nowrap text-left px-4 py-2 font-medium text-white-100">
              Ad Soyad
            </th>

            <th className="whitespace-nowrap text-left px-4 py-2 font-medium text-white-100">
              Bigo Kullanıcı Adı
            </th>

            <th className="whitespace-nowrap text-left px-4 py-2 font-medium text-white-100">
              Yatırılan Tutar
            </th>
            <th className="whitespace-nowrap text-left px-4 py-2 font-medium text-white-100">
              Elmas Miktarı
            </th>
            <th className="whitespace-nowrap text-left px-4 py-2 font-medium text-white-100">
              İşlem Tarihi
            </th>
            <th className="whitespace-nowrap text-left px-4 py-2 font-medium text-white-100">
              İşlem Durumu
            </th>
            <th className="whitespace-nowrap text-left px-4 py-2 font-medium text-white-100">
              İşlem
            </th>
          </tr>
        </thead>

        <tbody className="divide-y divide-gray-200">
          {data?.map((data) => (
            <tr key={data.id}>
              <td className="whitespace-nowrap px-4 py-2 font-medium text-white-100">
                {data.customer.first_name} {data.customer.last_name}
              </td>
              <td className="whitespace-nowrap px-4 py-2 font-medium text-white-100">
                {data.bigo_username}
              </td>
              <td className="whitespace-nowrap px-4 py-2 text-white-100">
                {data.payment.amount_try} ₺
              </td>
              <td className="whitespace-nowrap px-4 py-2 text-white-100">
                {data.amount_diamonds}
              </td>
              <td className="whitespace-nowrap px-4 py-2 text-white-100">
                {moment(data.created_at).format('Do MMMM YYYY, H:mm:ss')}
              </td>
              <td className="whitespace-nowrap px-4 py-2 text-white-100">
                {data.status === "completed" ? (
                  <OrderIndicator color="green">Gönderim Yapıldı.</OrderIndicator>
                ) : data.status === "pending_transfer" ? (
                  <OrderIndicator color="yellow">Gönderim Bekliyor.</OrderIndicator>
                ) : data.status === "failed" ? (
                  <OrderIndicator color="red">
                    Gönderim Reddedildi.
                  </OrderIndicator>
                ) : (
                  <OrderIndicator color="red">No Data</OrderIndicator>
                )}
              </td>
              <td className="whitespace-nowrap px-4 py-2">
                <Link
                  to={`/admin/orders/detail/${data.id}`}
                  className="inline-block rounded bg-indigo-600 px-4 py-2 text-xs font-medium text-white-100 hover:bg-indigo-700"
                >
                  Detaylı Gör
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>


*/

import React from 'react'
import { Link } from 'react-router-dom'

import moment from 'moment'
import 'moment/locale/tr'

moment.locale("tr");

const Payments = ({ data }) => {

  return (
    <div className="overflow-x-auto p-4">
      <table className="min-w-full divide-y-2 divide-gray-200 text-sm">
        <thead className="">
          <tr>
            <th className="whitespace-nowrap text-left px-4 py-2 font-medium text-white-100">
              Ad
            </th>

            <th className="whitespace-nowrap text-left px-4 py-2 font-medium text-white-100">
              Ödeme Tutarı
            </th>
            <th className="whitespace-nowrap text-left px-4 py-2 font-medium text-white-100">
              İşlem Tarihi
            </th>

            <th className="whitespace-nowrap text-left px-4 py-2 font-medium text-white-100">
              İşlem
            </th>
          </tr>
        </thead>

        <tbody className="divide-y divide-gray-200">
          {data?.map((data) => (
            <tr key={data.id}>
              <td className="whitespace-nowrap px-4 py-2 font-medium text-white-100">
                {data.full_name}
              </td>

              <td className="whitespace-nowrap px-4 py-2 text-white-100">

                {
                  Intl.NumberFormat('tr-TR', {
                    style: 'currency',
                    currency: 'TRY'
                  }).format(data.amount_try)
                }
              </td>

              <td className="whitespace-nowrap px-4 py-2 text-white-100">
                {moment(data.created_at).utcOffset('+03:00').format('Do MMMM YYYY, H:mm:ss')}
              </td>

              <td className="whitespace-nowrap px-4 py-2">
                <Link
                  to={`/admin/payments/detail/${data.id}`}
                  className="inline-block rounded bg-indigo-600 px-4 py-2 text-xs font-medium text-white-100 hover:bg-indigo-700"
                >
                  Detaylı Gör
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Payments;
